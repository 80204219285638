import React from "react"
import { Drawer, DrawerBody, DrawerContent, Flex, Box, Text, Icon } from "@chakra-ui/react"
import { theme } from "src/styles/theme"
import { MdClose } from "react-icons/md"
import { FaCircle } from "react-icons/fa"
import notifyServiceInstance from "@services/Notify/notifyService"
import { useNavigate } from "react-router-dom"
import { LoginContext } from "@context/loginContext"
import { UsuallyContext } from "@context/usuallyContex"
import { OportunityContext } from "@context/oportunityContex"
import Spinner from "../Spinner"
import { IconCreditRating } from "src/assets/iconCreditRating"
import { IconQuotes } from "src/assets/iconQuotes"

interface IDrawerSidebar {
  isOpen: boolean
  widthMd?: boolean
  onClose: () => void
  notifications: any[]
  setNotifications: React.Dispatch<React.SetStateAction<any[]>>
  setIsMarked: React.Dispatch<React.SetStateAction<boolean>>
}

const formatDate = (dateString: string | null): string => {
  if (!dateString) return "Não visualizado"
  const date = new Date(dateString)
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date)
}

const DrawerNotification = ({
  isOpen,
  onClose,
  widthMd,
  notifications,
  setNotifications,
  setIsMarked,
}: IDrawerSidebar) => {
  const navigate = useNavigate()
  const { setPageSidebar, pageSidebar } = React.useContext(LoginContext)
  const { setHashOportunity, setHashDetailOportunity } = React.useContext(UsuallyContext)
  const { setAccessCreateOp, setAccessQuote } = React.useContext(OportunityContext)

  const [loading, setLoading] = React.useState(false)

  const fetchNotifications = async () => {
    setLoading(true)
    try {
      const response = await notifyServiceInstance.getNotify()

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false)
        setNotifications([])
        console.log("status 400", response)
        return
      }
      if (response.status === 401) {
        setLoading(false)
        setNotifications([])
        return
      }

      // Verificar se há alguma notificação com exibido === null
      const hasUnreadNotifications = Array.isArray(response)
        ? response.some((notification) => notification.exibido === null)
        : false

      setIsMarked(hasUnreadNotifications)
      setNotifications(Array.isArray(response) ? response : [])
    } catch (error) {
      console.log("Erro ao buscar notificações:", error)
      setNotifications([])
    } finally {
      setLoading(false)
    }
  }

  const fetchMarkViewed = async ({ Id, tipoNotificacao }: { Id: string; tipoNotificacao: number }) => {
    setLoading(true)
    try {
      const response = await notifyServiceInstance.markViewed({
        Id,
        tipoNotificacao,
      })

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false)
        console.log("status 400", response)
        return
      }

      // fetchNotifications();
    } catch (error) {
      setLoading(false)
      console.error("Erro ao marcar como visualizada:", error)
    }
  }

  const handleClickNotify = (notification: any) => {
    if (notification?.tipoNotificacao === "NovaOP") {
      setHashOportunity("DetailOportunityComponent")
      setHashDetailOportunity("DetailOportunity")

      setAccessCreateOp((prevState: any) => ({
        ...prevState,
        oportunidadeId: notification?.oportunidadeId,
        oportunityIdSalesforce: notification?.opportunityIdSalesforce,
      }))
    } else if (notification?.tipoNotificacao === "RetornoAC") {
      setHashOportunity("DetailOportunityComponent")
      setHashDetailOportunity("DetailOportunity")

      setAccessCreateOp((prevState: any) => ({
        ...prevState,
        oportunidadeId: notification?.oportunidadeId,
      }))
    } else {
      setHashOportunity("DetailOportunityComponent")
      setHashDetailOportunity("ViewQuotesComponent")

      setAccessCreateOp((prevState: any) => ({
        ...prevState,
        oportunidadeId: notification?.oportunidadeId,
      }))

      setAccessQuote({
        nomeDaCotacao: notification?.nomeDaCotacao,
        idCotacaoSalesforce: notification?.idCotacaoSalesforce,
      })
    }

    setPageSidebar("Minhas oportunidades")
    localStorage.setItem("@option", "Minhas oportunidades")
    navigate("/oportunidades")
    onClose()
  }

  const handleNumberTypeNotification = (notification: any) => {
    if (notification?.tipoNotificacao === "NovaOP") return 1
    else if (notification?.tipoNotificacao === "RetornoAC") return 2
    else return 3
  }

  const handleIconTypeNotification = (notification: any) => {
    if (notification?.tipoNotificacao === "NovaOP")
      return (
        <svg width="20" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38443 19V12.0442L0.961426 6.5L4.97118 0H13.0287L17.0384 6.5L13.6154 12.0442V19L8.99993 17.4038L4.38443 19ZM5.38443 17.5328L8.99993 16.323L12.6154 17.5328V13H5.38443V17.5328ZM5.51918 1L2.11918 6.5L5.51918 12H12.4807L15.8807 6.5L12.4807 1H5.51918ZM7.94993 9.8635L5.11143 7.05L5.82493 6.3365L7.94993 8.4615L12.1749 4.2115L12.8884 4.9L7.94993 9.8635Z"
            fill={theme.primary}
          />
        </svg>
      )
    else if (notification?.tipoNotificacao === "RetornoAC") return <IconCreditRating width="20" height="20" />
    else return <IconQuotes width="20" height="20" />
  }

  // Separar notificações em lidas e não lidas
  const unreadNotifications = Array.isArray(notifications)
    ? notifications.filter((notification) => notification.visualizado === null)
    : []
  const readNotifications = Array.isArray(notifications)
    ? notifications.filter((notification) => notification.visualizado !== null)
    : []

  // React.useEffect(() => {
  //   if (isOpen) {
  //     fetchNotifications();
  //   }
  // }, [isOpen]);

  return (
    <Box display={"flex"} pos={"absolute"}>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerContent marginLeft={widthMd ? 0 : 230}>
          <DrawerBody>
            <Flex
              pos={"fixed"}
              top={0}
              left={0}
              flexDir={"column"}
              h={"100%"}
              background={"white"}
              zIndex={999}
              p={4}
              overflowY={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.primary,
                  borderRadius: "20px",
                },
              }}
            >
              <Flex justifyContent={"space-around"} alignItems={"center"}>
                <Text fontWeight={"bold"}>Notificações</Text>
                <Box
                  display={"flex"}
                  w={"fit-content"}
                  borderWidth={1}
                  borderColor={"transparent"}
                  fontSize="22px"
                  color={"gray"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  onClick={onClose}
                  _hover={{
                    borderWidth: 1,
                    borderColor: theme.text,
                    transitionDuration: "0.2s",
                    borderRadius: "8px",
                    color: theme.text,
                  }}
                >
                  <MdClose />
                </Box>
              </Flex>

              {loading ? (
                <Flex mt={8} flexDir={"column"} justifyContent={"center"} alignItems={"center"} h={"100%"}>
                  <Spinner />
                </Flex>
              ) : (
                <>
                  {/* Notificações Não Lidas */}
                  <Flex mt={8} flexDir={"column"}>
                    <Text fontSize={"12px"} color={theme.text50} fontWeight={"bold"}>
                      Não Lidas
                    </Text>

                    {unreadNotifications?.map((notification) => (
                      <Flex
                        key={notification.id}
                        mt={4}
                        justifyContent={"space-around"}
                        gap={2}
                        cursor={"pointer"}
                        _hover={{
                          textDecor: "underline",
                        }}
                        onClick={() => {
                          handleClickNotify(notification)
                          fetchMarkViewed({
                            Id: notification?.id,
                            tipoNotificacao: handleNumberTypeNotification(notification),
                          })
                        }}
                      >
                        <Box
                          display={"flex"}
                          borderWidth={1}
                          borderColor={theme.disable}
                          w={"45px"}
                          h={"35px"}
                          borderRadius={"full"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {handleIconTypeNotification(notification)}
                        </Box>

                        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} w={"fit-content"}>
                          <Text fontSize={"11px"} color={theme.primary} fontWeight={"bold"}>
                            {notification.titulo}
                          </Text>
                          <Text fontSize={"11px"} color={theme.text}>
                            {notification?.mensagem}
                          </Text>
                          <Text fontSize={"10px"} color={theme.text50}>
                            {formatDate(notification?.exibido)}
                          </Text>
                        </Box>

                        <Box display={"flex"} w={"fit-content"}>
                          <Icon fontSize="14px" color={theme.notification}>
                            <FaCircle />
                          </Icon>
                        </Box>
                      </Flex>
                    ))}
                  </Flex>

                  {/* Notificações Lidas */}
                  <Flex mt={8} flexDir={"column"}>
                    <Text fontSize={"12px"} color={theme.text50} fontWeight={"bold"}>
                      Lidas
                    </Text>

                    {readNotifications.map((notification) => (
                      <Flex
                        key={notification.id}
                        mt={4}
                        justifyContent={"space-around"}
                        gap={2}
                        cursor={"pointer"}
                        _hover={{
                          textDecor: "underline",
                        }}
                        onClick={() => handleClickNotify(notification)}
                      >
                        <Box
                          display={"flex"}
                          borderWidth={1}
                          borderColor={theme.disable}
                          w={"42px"}
                          h={"35px"}
                          borderRadius={"full"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.38443 19V12.0442L0.961426 6.5L4.97118 0H13.0287L17.0384 6.5L13.6154 12.0442V19L8.99993 17.4038L4.38443 19ZM5.38443 17.5328L8.99993 16.323L12.6154 17.5328V13H5.38443V17.5328ZM5.51918 1L2.11918 6.5L5.51918 12H12.4807L15.8807 6.5L12.4807 1H5.51918ZM7.94993 9.8635L5.11143 7.05L5.82493 6.3365L7.94993 8.4615L12.1749 4.2115L12.8884 4.9L7.94993 9.8635Z"
                              fill={theme.primary}
                            />
                          </svg>
                        </Box>

                        <Box display={"flex"} flexDir={"column"} justifyContent={"center"} w={"fit-content"}>
                          <Text fontSize={"11px"} color={theme.primary} fontWeight={"bold"}>
                            {notification.titulo}
                          </Text>
                          <Text fontSize={"11px"} color={theme.text}>
                            {notification.mensagem}
                          </Text>
                          <Text fontSize={"10px"} color={theme.text50}>
                            {formatDate(notification.visualizado)}
                          </Text>
                        </Box>
                      </Flex>
                    ))}
                  </Flex>
                </>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default DrawerNotification
