import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { useMessageDeleteToast } from "@hook/useMessageToast";
import { OportunityContext } from "@context/oportunityContex";
import { IconEdit } from "src/assets/IconEdit";
import { IconTrash } from "src/assets/IconTrash";

type Props = {
  data: any[];
  handleDeleteRealtionship: (idRelacionamentoComprador: string) => void;
  setindex: React.Dispatch<React.SetStateAction<number>>;
  setDisabledCreateDocument: React.Dispatch<React.SetStateAction<boolean>>;
  setAccount: React.Dispatch<React.SetStateAction<string>>;
  setAccountIdSalesforce: React.Dispatch<React.SetStateAction<string>>;
  setHash: React.Dispatch<React.SetStateAction<string>>;
};

const TableBuyers = ({
  data,
  handleDeleteRealtionship,
  setindex,
  setDisabledCreateDocument,
  setAccount,
  setAccountIdSalesforce,
  setHash,
}: Props) => {
  const displayMessageDelete = useMessageDeleteToast();
  const {
    setAccessRelationship,
    setIdRelacionamentoComprador,
    setAccessAttachments,
  } = React.useContext(OportunityContext);

  const handleTypeRelationshipReverse = (typeRelationship: number) => {
    if (typeRelationship === 1) return "Compositor de Renda";
    if (typeRelationship === 2) return "Cônjuge";
    if (typeRelationship === 3) return "Representante Legal";
    if (typeRelationship === 4) return "Fiador";
    if (typeRelationship === 5) return "Comprador Principal";
    return "";
  };

  function handleBackgroundFase(value: string) {
    if (value?.toLowerCase() !== "comprador principal") return theme.disable100;
    return "#F0F4FD";
  }

  function handleTextColorFase(value: string) {
    if (value?.toLowerCase() !== "comprador principal") return theme.text;

    return theme.primary;
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead
          h="60px"
          position="sticky"
          top="0"
          zIndex="1"
          bg="white"
          boxShadow="sm"
        >
          <Tr>
            <Th w="17%" textAlign="center">
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                <Text
                  alignItems={"center"}
                  fontSize={11}
                  w={"fit-content"}
                  cursor={"pointer"}
                >
                  Nome
                </Text>
              </Box>
            </Th>
            <Th w="17%" textAlign="center">
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                <Text
                  alignItems={"center"}
                  fontSize={11}
                  w={"fit-content"}
                  cursor={"pointer"}
                >
                  Tipo
                </Text>
              </Box>
            </Th>
            <Th w="17%">
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                <Text
                  alignItems={"center"}
                  fontSize={11}
                  cursor={"pointer"}
                  w={"fit-content"}
                >
                  CPF/CNPJ
                </Text>
              </Box>
            </Th>
            <Th w="17%" textAlign="center">
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                <Text
                  alignItems={"center"}
                  fontSize={11}
                  w={"fit-content"}
                  cursor={"pointer"}
                >
                  Percentual
                </Text>
              </Box>
            </Th>
            <Th w="17%" textAlign="center">
              <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                <Text
                  alignItems={"center"}
                  fontSize={11}
                  w={"fit-content"}
                  cursor={"pointer"}
                >
                  Ações
                </Text>
              </Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.map(
            (
              {
                idRelacionamentoComprador,
                accountIdSalesforce,
                nome,
                cpf,
                codigoRelacionamentoComComprador,
                percentualDoTitular,
                tipoRelacionamento,
                compradorPrincipal,
                contaPossuiCPF,
              }: any,
              i
            ) => (
              <Tr
                key={idRelacionamentoComprador}
                _hover={{ bg: "#E2E8F0" }}
                onClick={() => {}}
              >
                <Td w="17%">
                  <Flex flexDir={"column"} gap={"4px"} fontSize={"14px"}>
                    <Text fontWeight={"semibold"}>{nome}</Text>
                    <Text fontSize={"14px"}>
                      {codigoRelacionamentoComComprador}
                    </Text>
                  </Flex>
                </Td>

                <Td w="17%">
                  <Flex
                    bg={handleBackgroundFase(
                      handleTypeRelationshipReverse(tipoRelacionamento)
                    )}
                    color={handleTextColorFase(
                      handleTypeRelationshipReverse(tipoRelacionamento)
                    )}
                    fontSize={"14px"}
                    w={"fit-content"}
                    h={"25px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"16px"}
                    p={"5px"}
                  >
                    <Text>
                      {!handleTypeRelationshipReverse(tipoRelacionamento)
                        ? "Comprador principal"
                        : handleTypeRelationshipReverse(tipoRelacionamento)}
                    </Text>
                  </Flex>
                </Td>

                <Td w="17%">
                  <Flex fontSize={"14px"}>
                    <Text>{cpf}</Text>
                  </Flex>
                </Td>

                <Td w="17%">
                  <Flex fontSize={"14px"}>
                    <Text>{percentualDoTitular}%</Text>
                  </Flex>
                </Td>

                <Td w="17%">
                  <Flex gap={"20px"}>
                    <Icon
                      fontSize="20px"
                      color={theme.primary}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      onClick={() => {
                        setindex(i);
                        setDisabledCreateDocument(contaPossuiCPF);
                        setAccessAttachments({
                          codigoRelacionamentoComComprador,
                          cpf,
                        });
                        setIdRelacionamentoComprador(idRelacionamentoComprador);
                        setAccount(nome);

                        setAccountIdSalesforce(accountIdSalesforce);
                        setAccessRelationship({
                          codigoRelacionamentoComComprador,
                          nomeConta: nome,
                        });
                        setHash("ViewRelationship");
                      }}
                    >
                      <IconEdit width="22px" height="22px" />
                    </Icon>
                    <Icon
                      fontSize="21px"
                      color={theme.primary}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      cursor={compradorPrincipal ? "default" : "pointer"}
                      onClick={() => {
                        if (compradorPrincipal) return;
                        else {
                          displayMessageDelete({
                            title: `Deseja excluir o ${codigoRelacionamentoComComprador}?`,
                            message:
                              "Esse processo é irreversível, após a exclusão o relacionamento será desvinculado dessa oportunidade.",
                            onClick: () =>
                              handleDeleteRealtionship(
                                idRelacionamentoComprador
                              ),
                          });
                        }
                      }}
                    >
                      <IconTrash
                        width="22px"
                        height="22px"
                        color={compradorPrincipal ? theme.disable : "#263958"}
                      />
                    </Icon>
                  </Flex>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableBuyers;
