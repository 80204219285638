interface IIcon {
  width: string;
  height: string;
  color: string;
}

export const IconTrash = ({ width, height, color }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6155 15.9997C2.168 15.9997 1.78683 15.8424 1.472 15.5277C1.15733 15.2129 1 14.8317 1 14.3842V1.99972H0V0.999719H4V0.230469H10V0.999719H14V1.99972H13V14.3842C13 14.8446 12.8458 15.2289 12.5375 15.5372C12.2292 15.8456 11.8448 15.9997 11.3845 15.9997H2.6155ZM12 1.99972H2V14.3842C2 14.5637 2.05767 14.7112 2.173 14.8267C2.2885 14.9421 2.436 14.9997 2.6155 14.9997H11.3845C11.5385 14.9997 11.6796 14.9356 11.8077 14.8075C11.9359 14.6793 12 14.5382 12 14.3842V1.99972ZM4.80775 12.9997H5.80775V3.99972H4.80775V12.9997ZM8.19225 12.9997H9.19225V3.99972H8.19225V12.9997Z"
      fill={color}
    />
  </svg>
);
