import React from "react";
import * as Styled from "./styles";
import { Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IInputLogin {
  placeholder?: string;
  isLabel?: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  message?: any;
  error?: any;
  onInput?: React.FormEventHandler<HTMLInputElement>;
}

const InputPassword = ({
  value,
  isLabel,
  onChange,
  placeholder,
  message,
  error,
  onInput
}: IInputLogin) => {
  const [showInput, setShowInput] = React.useState<boolean>(false);

  return (
    <Styled.Area>
      {isLabel && (
        <Text fontSize={"14px"} color={theme.text} mb={"8px"}>
          Senha:
        </Text>
      )}

      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onInput={onInput}
          isInvalid={error}
          type={showInput ? "text" : "password"}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.text }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
        <InputRightElement>
          {showInput ? (
            <Styled.IconAiOutlineEyeInvisible
              onClick={() => setShowInput(false)}
            />
          ) : (
            <Styled.IconAiOutlineEye onClick={() => setShowInput(true)} />
          )}
        </InputRightElement>
      </InputGroup>

      <span>{message}</span>
    </Styled.Area>
  );
};

export default InputPassword;
