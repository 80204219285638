import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "src/styles/theme";
import * as Styled from "../Steps/stylesStepTab";
import InputText from "@components/Sales/Inputs/InputText";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionBankCreditRating } from "@helpers/optionSelect";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import Button from "@components/common/Button";
import { MdOutlineEditNote } from "react-icons/md";
import { optionCorresponding, optionEnterprise } from "./options";
import { OportunityContext } from "@context/oportunityContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import { IAccordionCreditRatingComponent } from "src/@types/Sales";

const AccordionCreditRatingComponent = ({
  formik,
  dataGetCreditRating,
  getEnterprises,
  dataEnterprises,
  setIdEnterprise,
  bank,
  setBank,
  estimatedPurchaseValue,
  setEstimatedPurchaseValue,
  futureSale,
  setFutureSale,
  enterprises,
  setEnterprises,
  selectEnterprises,
  setSelectEnterprises,
  loadingEnterprise,
  acActivity,
  setAcActivity,
  edit = false,
  setEdit,
  loadingUpdate,
  handleUpdateCreditRating,
  handleSendToSAFI,
  status,

  dataCorresponding,
  setCorrespondingId,
  setCnpjCorresponding,
  selectCorresponding,
  setSelectCorresponding,
  corresponding,
  setCorresponding,
  loadingCorresponding,
  handleGetCorresponding,
  isCorresponding,
  loading
}: IAccordionCreditRatingComponent) => {
  const { faseOp, nomeEmpreendimentoSales, accessCreateOp, imobiliariaNome } =
    React.useContext(OportunityContext);

  const formatMoney = (valor: number) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
    return newValue;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(Number(inputValue) / 100);

    setEstimatedPurchaseValue(formattedValue);
  };

  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectEnterprises,
    setSelectEnterprises,
    setEnterprises,
    setIdEnterprise
  );

  const renderedOptionsCorresponding = optionCorresponding(
    dataCorresponding,
    selectCorresponding,
    setSelectCorresponding,
    setCorresponding,
    setCorrespondingId,
    setCnpjCorresponding
  );

  const optionAcAtiva = [
    {
      id: "sim",
      name: "Sim",
    },
    {
      id: "não",
      name: "Não",
    },
  ];

  const inAnalisys = formik.values.statusBankRating === "Enviado para análise";
  const aproved =
    formik.values.status === "Análise aprovada" ||
    formik.values.status === "Enviado para análise";

  const handleCancelButton = () => {
    setEdit(false);
    setBank(dataGetCreditRating?.banco);
    setEstimatedPurchaseValue(
      formatMoney(dataGetCreditRating?.valorEstimadoCompraEVenda?.toString())
    );
    setAcActivity(dataGetCreditRating?.acAtiva ? "Sim" : "Não");
    setSelectEnterprises(nomeEmpreendimentoSales);
    setEnterprises(nomeEmpreendimentoSales);
  };

  React.useEffect(() => {
    if (status === "Rascunho" || status === "Reprovada") {
      setEdit(false);
    }
  }, [setEdit, status]);

  if (loading) {
    return (
      <Styled.LoadingSpinner>
        <Spinner />
      </Styled.LoadingSpinner>
    );
  }

  return (
    <Accordion allowMultiple>
      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Informações da análise de crédito
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            <InputSelect
              isRequirement
              readOnly={!edit}
              widht="24%"
              title="Banco:"
              label={bank || "Selecione uma opção"}
              isSelected={bank}
              options={optionBankCreditRating}
              selectedOption={bank}
              onChange={(e) => setBank(e.currentTarget.value)}
            />

            <InputText
              isRequirement
              readOnly
              label="Conta:"
              placeholder="Conta"
              name="account"
              value={formik.values.account}
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="Valor estimado de compra e venda:"
              placeholder="Valor estimado de compra e venda"
              name="Valor estimado de compra e venda"
              value={estimatedPurchaseValue}
              onChange={handleChange}
            />

            <InputText
              isRequirement
              readOnly
              label="Venda futura:"
              placeholder="Venda futura"
              name="futureSale"
              value={futureSale}
            />

            <InputSelectSearch
              width="24%"
              height="15%"
              title="Empreendimento:"
              placeholder="Pesquise por empreendimento"
              value={selectEnterprises}
              onChangeInput={(e) => setSelectEnterprises(e.target.value)}
              isSelected={enterprises}
              onChangeIcon={() => {
                setEnterprises("");
                setSelectEnterprises("");
              }}
              onClickSearchIcon={getEnterprises}
              loading={loadingEnterprise}
              disabled={!edit}
            >
              {renderedOptions}
            </InputSelectSearch>

            <InputText
              isRequirement
              readOnly
              label="Imobiliária:"
              placeholder="Imobiliária"
              name="realState"
              value={imobiliariaNome}
            />

            <InputText
              isRequirement
              readOnly
              label="Oportunidade:"
              placeholder="Oportunidade"
              name="oportunity"
              value={`${accessCreateOp.nomeDaConta}(${accessCreateOp.oportunidadeId})`}
            />

            <InputSelect
              readOnly={!edit}
              isRequirement
              widht="22%"
              title="AC ativa:"
              label={acActivity || "Selecione uma opção"}
              isSelected={acActivity}
              options={optionAcAtiva}
              selectedOption={acActivity}
              onChange={(e) => setAcActivity(e.currentTarget.value)}
            />

            {!isCorresponding && (
              <InputSelectSearch
                disabled={!edit}
                width="32%"
                height="28%"
                title="Correspondente:"
                placeholder="Pesquise por correspondente"
                value={selectCorresponding}
                onChangeInput={(e) => setSelectCorresponding(e.target.value)}
                isSelected={corresponding}
                onChangeIcon={() => {
                  setCorresponding("");
                  setSelectCorresponding("");
                }}
                onClickSearchIcon={handleGetCorresponding}
                loading={loadingCorresponding}
              >
                {renderedOptionsCorresponding}
              </InputSelectSearch>
            )}
          </Styled.ContainerBoxInputsCreditRating>

          <Styled.ContainerBoxInputsObservations>
            <InputTextArea
              readOnly={!edit}
              label="Observações:"
              placeholder=""
              name="observations"
              value={formik.values.observations}
              onChange={formik.handleChange}
            />
            <Styled.ContainerBoxButtonObservations>
              <Button
                title={
                  <Styled.TitleButtons>
                    <MdOutlineEditNote />
                    EDITAR
                  </Styled.TitleButtons>
                }
                onClick={() => setEdit(true)}
                disabled={edit || faseOp || inAnalisys || aproved}
              />
            </Styled.ContainerBoxButtonObservations>
          </Styled.ContainerBoxInputsObservations>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={handleCancelButton}
                disabled={!edit}
              />

              <Button
                title={loadingUpdate ? <Spinner /> : "SALVAR"}
                onClick={handleUpdateCreditRating}
                disabled={loadingUpdate || !edit}
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Resultado da Análise
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            <InputText
              isRequirement
              readOnly
              label="Status:"
              placeholder="Status"
              name="status"
              value={formik.values.status}
            />
            <InputText
              isRequirement
              readOnly
              label="Status Análise Banco:"
              placeholder="Status Análise Banco"
              name="statusBankRating"
              value={formik.values.statusBankRating}
            />
            <InputText
              isRequirement
              readOnly
              label="Motivo Pendência SAFI:"
              placeholder="Motivo Pendência SAFI"
              name="pendingSAFI"
              value={formik.values.pendingSAFI}
            />
            <InputTextEdit
              isRequirement
              readOnly
              label="Taxa Banco:"
              placeholder="Taxa Banco"
              name="bankRate"
              value={formik.values.bankRate}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue(
                  "bankRate",
                  String(filterDecimalNumber(e.target.value))
                );
              }}
            />
            <InputText
              isRequirement
              readOnly
              tooltip={
                formik.values.justification ||
                formik?.values?.justification?.length > 30
              }
              label="Justificativa:"
              placeholder="Justificativa"
              name="justification"
              value={formik.values.justification}
            />
            <InputText
              isRequirement
              readOnly
              label="Tabela de amortização:"
              placeholder="Tabela de amortização"
              name="amortizationTable"
              value={formik.values.amortizationTable}
            />
            <InputText
              isRequirement
              readOnly
              label="Modalidade:"
              placeholder="Modalidade"
              name="modality"
              value={formik.values.modality}
            />
            <InputText
              isRequirement
              readOnly
              label="SICAQ:"
              placeholder="SICAQ"
              name="Sicaq"
              value={formik.values.Sicaq}
            />
            <InputText
              isRequirement
              readOnly
              label="Valor de avaliação:"
              placeholder="Valor de avaliação"
              name="appraisalValue"
              value={formik.values.appraisalValue}
            />
            <InputText
              isRequirement
              readOnly
              label="Idade mais velho:"
              placeholder="Idade mais velho"
              name="olderAge"
              value={formik.values.olderAge}
            />
            <InputText
              isRequirement
              readOnly
              label="Valor do financiamento:"
              placeholder="Valor do financiamento"
              name="financingValue"
              value={formik.values.financingValue}
            />
            <InputText
              isRequirement
              readOnly
              label="Renda apurada:"
              placeholder="Renda apurada"
              name="calculatedIncome"
              value={formik.values.calculatedIncome}
            />
            <InputText
              isRequirement
              readOnly
              label="Valor de subsídio:"
              placeholder="Valor de subsídio"
              name="subsidyAmount"
              value={formik.values.subsidyAmount}
            />
            <InputText
              isRequirement
              readOnly
              label="Possui dependentes:"
              placeholder="Possui dependentes"
              name="hasDependents"
              value={formik.values.hasDependents}
            />
            <InputText
              isRequirement
              readOnly
              label="FGTS apurado:"
              placeholder="FGTS apurado"
              name="fgtsCalculated"
              value={formik.values.fgtsCalculated}
            />
            <InputText
              isRequirement
              readOnly
              label="Tipo de Renda:"
              placeholder="Tipo de Renda"
              name="typeIncome"
              value={formik.values.typeIncome}
            />
            <InputText
              isRequirement
              readOnly
              label="Valor parcela aprovada:"
              placeholder="Valor parcela aprovada"
              name="approvedInstallmentValue"
              value={formik.values.approvedInstallmentValue}
            />
            <InputText
              isRequirement
              readOnly
              label="Prazo financiamento:"
              placeholder="Prazo financiamento"
              name="financingTerm"
              value={formik.values.financingTerm}
            />
            <InputText
              isRequirement
              readOnly
              label="1º prestação financiada:"
              placeholder="1º prestação financiada"
              name="firstInstallmentFinanced"
              value={formik.values.firstInstallmentFinanced}
            />
          </Styled.ContainerBoxInputsCreditRating>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Retorno Integração SAFI
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsCreditRating>
            <InputText
              isRequirement
              readOnly
              label="FID:"
              placeholder="FID"
              name="fid"
              value={formik.values.fid}
            />
            <InputText
              isRequirement
              readOnly
              label="Status da integração:"
              placeholder="Status da integração"
              name="integrationStatus"
              value={formik.values.integrationStatus}
            />
            <InputText
              isRequirement
              readOnly
              label="Tipo:"
              placeholder="Tipo"
              name="type"
              value={formik.values.type}
            />
            <InputText
              isRequirement
              readOnly
              label="Data primeiro envio análise:"
              placeholder="Data primeiro envio análise"
              name="dateFirstSentAnalysis"
              value={getDateOrNull(formik.values.dateFirstSentAnalysis)}
            />
            <InputText
              isRequirement
              readOnly
              label="Data último envio análise:"
              placeholder="Data último envio análise"
              name="dateLastSentAnalysis"
              value={getDateOrNull(formik.values.dateLastSentAnalysis)}
            />
            <InputText
              isRequirement
              readOnly
              label="Data aprovação SAFI:"
              placeholder="Data aprovação SAFI"
              name="safiApprovalDate"
              value={getDateOrNull(formik.values.safiApprovalDate)}
            />
          </Styled.ContainerBoxInputsCreditRating>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionCreditRatingComponent;

export const getDateOrNull = (s?: string | null) => {
  if (!s) {
    return "";
  } else {
    const fragments = s.split("T")[0]?.split("-");
    if (fragments?.length !== 3) {
      return s;
    } else {
      return `${fragments[2]}/${fragments[1]}/${fragments[0]}`;
    }
  }
};

export const filterDecimalNumber = (s: string) => {
  let foundDot = false;
  let numberString = "";

  for (let i = 0; i < s?.length; i++) {
    const character = s[i];
    if (character >= "0" && character <= "9") {
      numberString += character;
    } else if (
      (character === "." || character === ",") &&
      foundDot === false &&
      i !== 0
    ) {
      numberString += ".";
      foundDot = true;
    }
  }

  return numberString;
};
