import React, { FormEvent } from "react";
import * as Styled from "../stylesView&Create";
import { useFormik } from "formik";
import { OportunityContext } from "@context/oportunityContex";
import Spinner from "@components/common/Spinner";
import { MdOutlineEditNote } from "react-icons/md";
import Button from "@components/common/Button";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import MessageToast from "@components/Sales/MessageToast";
import quotesService from "@services/Quotes/QuotesService";
import AccordionQuotesComponent from "../../components/AccordionQuotesComponent";
import { AiOutlineInfoCircle } from "react-icons/ai";
import TablePortion from "@components/Quotes/TablePortion";
import TableProposalAnalysis from "@components/Quotes/TableProposalAnalysis";
import { IValidationQuotesSchema } from "src/@types/Quotes";
import Modal from "@components/Sales/Modal";
import CreateMensaPsComponent from "./CreateMensaPsComponent";
import { BiPlus } from "react-icons/bi";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { RxUpdate } from "react-icons/rx";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { IViewQuotesComponent } from "src/@types/Sales";
import ViewChartsComponent from "./ViewChartsComponent";
import UpdateDataClientComponent from "../../components/UpdateDataClientComponent";
import AlertCompareValue from "@components/Sales/AlertCompareValue";
import { UsuallyContext } from "@context/usuallyContex";
import NotifyAC from "@components/Quotes/NotifyAC";
import { Box, Flex } from "@chakra-ui/react";
import RadioSelect from "@components/Sales/Inputs/RadioSelect";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import { optionCreditRating } from "../../components/options";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import InputText from "@components/Sales/Inputs/InputText";
import { filterDecimalNumber } from "../../components/AccordionCreditRatingComponent";

const ViewQuotesComponent = ({
  hash,
  isEdit = false,
  setIsEdit,
  canEdit,
}: IViewQuotesComponent) => {
  const { signOut } = React.useContext(LoginContext);
  const { hashDetailOportunity } = React.useContext(UsuallyContext);
  const {
    nomeConta,
    accessQuote,
    accessCreateOp,
    faseOp,
    setPaymentCondition,
    paymentCondition,
    accessCreditRating,
    setIsSendToApproval,
    isSendToApproval,
    statusQuote,
    setSuccessUpdateAddresModal,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
  } = React.useContext(OportunityContext);
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const [successEditQuotes, setSuccessEditQuotes] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [dataQuotes, setDataQuotes] = React.useState<IValidationQuotesSchema>();

  const [loadingApproval, setLoadingApproval] = React.useState(false);

  const [loadingQuotes, setLoadingQuotes] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [creditRating, setCreditRating] = React.useState("");
  const [selectCreditRating, setSelectCreditRating] = React.useState("");
  const [selectCreditRatingId, setSelectCreditRatingId] = React.useState("");
  const [dataCreditRating, setDataCreditRating] = React.useState([]);
  const [loadingCreditRating, setLoadingCreditRating] = React.useState(false);
  const [discount, setDiscount] = React.useState("");

  const [showCreateMensalPs, setShowCreateMensalPs] = React.useState(false);
  const [successCreateMensalPs, setSuccessCreateMensalPs] =
    React.useState(false);
  const [maxQuantity, setMaxQuantity] = React.useState(0);

  const [dataProposalAnalysis, setDataProposalAnalysis] = React.useState([]);
  const [existMensalPs, setExistMensalPs] = React.useState(false);
  const [emptyPortion, setEmptyPortion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [parcelamentoSalvo, setParcelamentoSalvo] = React.useState(false);
  const [parcelaAdicionadaNaoSalva, setParcelaAdicionadaNaoSalva] =
    React.useState(false);
  const [openCharts, setOpenCharts] = React.useState(false);
  const [showModalUpdateDate, setShowModalUpdateDate] = React.useState(false);
  const [showModalUpdateAdress, setShowModalUpdateAdress] =
    React.useState(false);
  const [bonusAdimplencia, setBonusAdimplencia] = React.useState(0);
  const [valorFinalKit, setValorFinalKit] = React.useState(0);
  const [valorTotalUnidade, setValorTotalUnidade] = React.useState(0);
  const [compareValue, setCompareValue] = React.useState(false);

  const [relationship, setRelationship] = React.useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [showModalAc, setShowModalAc] = React.useState(false);
  const [valueRadioButton, setValueRadioButton] = React.useState("");
  const [stepModal, setStepModal] = React.useState(0);
  const [flagNaoPodeVincularAc, setFlagNaoPodeVincularAc] =
    React.useState(false);
  const [bkpAc, setBkpAc] = React.useState("");

  const handleSalvarParcelamento = () => {
    setParcelamentoSalvo(true);
    setParcelaAdicionadaNaoSalva(false);
  };

  const handleAdicionarParcela = () => {
    setParcelaAdicionadaNaoSalva(true);
    setParcelamentoSalvo(false);
  };

  const disableEdit = dataQuotes?.status?.includes("nálise");

  const disableApprove = dataQuotes?.status?.toLowerCase()?.includes("aprovad");

  const valueNumberFormat = (valor: any) => {
    const newValor = valor
      ?.toString()
      .replace("R$", "")
      .replace(".", "")
      .replace(",", ".");
    const valorNumerico = parseFloat(newValor);
    return valorNumerico;
  };

  const formatMoney = (valor: any) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
    return newValue;
  };

  const initialValues = {
    nameAccount: nomeConta || "",
    proposalDate: dataQuotes?.dataProposta || "",
    paymentTerms: dataQuotes?.nomeCondicaoPagamentoMatrizSelecionada || "",
    status: dataQuotes?.status || "",
    product: dataQuotes?.nomeProduto || "",
    observations: dataQuotes?.observacao || "",

    fgtsCalculated: formatMoney(dataQuotes?.fgtsApurado) || "",
    subsidyAssessed: formatMoney(dataQuotes?.subsidioAvaliado) || "",
    financingCreditAssessment:
      formatMoney(dataQuotes?.financiamentoAvaliacaoDeCredito) || "",
    calculatedIncome: formatMoney(dataQuotes?.rendaApurada) || "",
    olderAge: dataQuotes?.idadeMaisVelho || 0,
    bankFee: dataQuotes?.taxaBanco || 0,

    income: formatMoney(dataQuotes?.renda) || "",
    totalUnitValue: dataQuotes?.valorTotalUnidade || 0,
    defaultBonusValue: formatMoney(dataQuotes?.valorBonusAdimplencia) || "",
    discount: formatMoney(dataQuotes?.desconto) || "",
    financingProvision: formatMoney(dataQuotes?.prestacaoFinanciamento) || "",

    balance: dataQuotes?.saldo || 0,
    totalBalanceInterest: dataQuotes?.saldoTotalComJuros || 0,
    incomeCommitmentInstallment: dataQuotes?.comprometimentoRendaParcela || 0,

    incomeCommitmentProSoluto: dataQuotes?.comprometimentoRendaProSoluto || 0,
    proSolutoInterspersed: dataQuotes?.proSolutoMensalIntercaladas || 0,
    proSolutoTotal: dataQuotes?.proSolutoTotal || 0,
    proSolutoPercentage: dataQuotes?.percentualProSoluto || 0,
    proSolutoDeficiency: dataQuotes?.proSolutoComCarencia || 0,
    proSolutoMonthly: dataQuotes?.proSolutoMensal || 0,
    maximumMonthlyIncome: dataQuotes?.maximoRendaMensal || 0,
    higherProSoluto: dataQuotes?.maiorValorProSoluto || 0,
    psPostWork: dataQuotes?.psPosObra || 0,
    psPostWorkPercent: dataQuotes?.psPsObraPercentual || 0,
    interleavedIncome: dataQuotes?.rendaIntercaladaPercentual || 0,
    totalMonthsCorrection: dataQuotes?.totalMesesCorrecao || 0,

    totalAmountFees: dataQuotes?.valorTotalTaxas || 0,
    rateInterest: dataQuotes?.jurosTaxa || 0,
    numberFeeInstallments: dataQuotes?.quantidadeParcelasTaxa || 0,
    feePortion: dataQuotes?.parcelaTaxa || 0,
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (valueRadioButton === "Preencher os dados da avaliação manualmente") {
        handleSubmitUpdateAC(values);
      } else {
        setLoadingUpdate(true);
        setSuccessEditQuotes(false);
        setError(false);
        try {
          const response = await quotesService.updateQuote({
            idAvaliacaoCreditoSalesforce:
              selectCreditRatingId === ""
                ? accessCreditRating.idAvaliacaoCreditoSalesforce
                : selectCreditRatingId,
            idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
            desconto: valueNumberFormat(values.discount),
            jurosTaxa: Number(values.rateInterest),
            quantidadeParcelasTaxa: Number(values.numberFeeInstallments),
            valorTotalTaxas: valueNumberFormat(values.totalAmountFees),
            fgtsApurado: valueNumberFormat(values.fgtsCalculated),
            financiamentoAvaliacaoDeCredito: valueNumberFormat(
              values.financingCreditAssessment
            ),
            idadeMaisVelho: Number(values.olderAge),
            prestacaoFinanciamento: valueNumberFormat(
              values.financingProvision
            ),
            renda: valueNumberFormat(values.income),
            rendaApurada: valueNumberFormat(values.calculatedIncome),
            subsidioAvaliado: valueNumberFormat(values.subsidyAssessed),
            taxaBanco: Number(values.bankFee),
            observacao: values.observations,
            flagNaoPodeVincularAc:
              valueRadioButton === "Preencher os dados da avaliação manualmente"
                ? false
                : true,
          });

          if (response.status >= 400 && response?.status !== 401) {
            setLoadingUpdate(false);
            setError(true);
            console.log("status 400", response);
            return;
          }
          if (response.status === 401) {
            setLoadingUpdate(false);
            setIsUnauthorized(true);
            return;
          }

          displayMessage("Cotação editada com sucesso!");
          setSuccessEditQuotes(true);
          setLoadingUpdate(false);
          setEdit(false);
          setShowModalAc(false);
          handleGetQuotesById();
        } catch (error) {
          setLoadingUpdate(false);
          console.log("ErrorHandleUpdateQuote", error);
        }
      }
    },
  });

  const handleGetQuotesById = async () => {
    setLoadingQuotes(true);
    try {
      const response = await quotesService.getQuotesById({
        IdCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingQuotes(false);
        setError(true);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingQuotes(false);
        setIsUnauthorized(true);
        return;
      }

      setFlagNaoPodeVincularAc(response?.flagNaoPodeVincularAc);
      setValorFinalKit(response?.valorFinalKit);
      setValorTotalUnidade(response?.valorTotalUnidade);
      setBonusAdimplencia(response?.bonusAdimplencia);
      setPaymentCondition(response?.nomeCondicaoPagamentoMatrizSelecionada);
      setDataProposalAnalysis(
        response?.mensagensProposta ? response?.mensagensProposta : []
      );
      setMaxQuantity(
        response?.maximoParcelasCondicaoPagamentoMatrizSelecionada
      );
      setDataQuotes(response);

      if (response.idAvaliacaoCreditoSalesforce !== null) {
        const creditRating =
          await oportunityServiceInstance.getCreditRatingById({
            IdAvaliacaoCreditoSalesforce: response.idAvaliacaoCreditoSalesforce,
          });

        if (creditRating.nome !== undefined) {
          setBkpAc(creditRating.nome);
          setSelectCreditRating(creditRating.nome);
          setCreditRating(creditRating.nome);
          setSelectCreditRatingId(response.idAvaliacaoCreditoSalesforce);
        }
      } else {
        setSelectCreditRating("");
        setCreditRating("");
        setSelectCreditRatingId("");
      }
      setLoadingQuotes(false);
    } catch (error) {
      setLoadingQuotes(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleGetCreditRatings = async () => {
    setLoadingCreditRating(true);
    try {
      const response = await oportunityServiceInstance.getCreditRating({
        OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        somenteAprovadas: true,
      });

      if (response.status === 401) {
        setLoadingCreditRating(false);
        return;
      }

      setDataCreditRating(response ? response : []);
      setLoadingCreditRating(false);
    } catch (error) {
      setLoadingCreditRating(false);
      console.log("ErrorHandleGetCreditRating", error);
    }
  };

  const handleSendToApproval = async () => {
    setLoadingApproval(true);
    setError(false);
    try {
      const response = await quotesService.sendToApproval({
        idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingApproval(false);
        setError(true);
        setErrorMessage(response?.data?.mensagem);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingApproval(false);
        setIsUnauthorized(true);
        return;
      }

      setIsUpdateAdressIncome(false);
      setIsUpdateAdress(false);

      setIsSendToApproval(true);
      setLoadingApproval(false);
      setSuccessUpdateAddresModal(false);
      setShowModalUpdateAdress(false);
      setShowModalUpdateDate(false);
      displayMessage("Cotação enviada para aprovação!");
      setCurrentIndex(0);
    } catch (error) {
      setLoadingApproval(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    formik.resetForm(initialValues);
    setSelectCreditRating("");
    setSelectCreditRatingId("");
  };

  const isAssociative =
    paymentCondition?.toLowerCase()?.includes("associativ") ||
    paymentCondition?.toLowerCase()?.includes("sfh");

  const disabledIncome = formik.getFieldProps("income").value === "R$ 0,00";
  const disabledFinancingProvision =
    formik.getFieldProps("financingProvision").value === "R$ 0,00";

  const handleOptionChange = (value: string) => {
    setValueRadioButton(value);
  };

  const handleDeleteAc = () => {
    if (flagNaoPodeVincularAc) {
      setSelectCreditRating(bkpAc);
      setCreditRating(bkpAc);
    } else {
      setSelectCreditRating("");
      setCreditRating("");
      setSelectCreditRatingId("");
      setFlagNaoPodeVincularAc(false);
    }
  };

  const handleCloseModal = () => {
    handleDeleteAc();
    setStepModal(0);
    setShowModalAc(false);
    setValueRadioButton("");
  };

  const creditRatingOptions = optionCreditRating(
    dataCreditRating,
    selectCreditRating,
    setSelectCreditRating,
    setCreditRating,
    setSelectCreditRatingId
  );

  const handleGetCreditRatingById = async () => {
    setLoadingUpdate(true);
    try {
      const creditRating = await oportunityServiceInstance.getCreditRatingById({
        IdAvaliacaoCreditoSalesforce: selectCreditRatingId,
      });

      if (creditRating.status >= 400 && creditRating?.status !== 401) {
        setLoadingUpdate(false);
        setError(true);
        console.log("status 400", creditRating);
        return;
      }

      setBkpAc(creditRating.nome);

      formik.setFieldValue(
        "fgtsCalculated",
        formatMoney(creditRating.fgtsApurado)
      );
      formik.setFieldValue(
        "subsidyAssessed",
        formatMoney(creditRating.valorDeSubsidio)
      );
      formik.setFieldValue(
        "financingCreditAssessment",
        formatMoney(creditRating.valorDoFinanciamento)
      );
      formik.setFieldValue(
        "calculatedIncome",
        formatMoney(creditRating.rendaApurada)
      );
      formik.setFieldValue("olderAge", creditRating.idadeMaisVelho);
      formik.setFieldValue("bankFee", creditRating.taxaBanco);

      formik.handleSubmit();
    } catch (error) {
      setLoadingUpdate(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleChangeMoneyDiscount = (
    event: React.ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>
  ) => {
    let inputValue = (event.target as HTMLInputElement).value;

    inputValue = inputValue.replace(/\D/g, "");

    const numericValue = Number(inputValue) / 100;

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);

    return { numericValue, formattedValue };
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    return inputValue;
  };

  const handleSubmitUpdateAC = async (values: any) => {
    setLoadingUpdate(true);
    try {
      const response = await quotesService.updateQuote({
        idAvaliacaoCreditoSalesforce:
          selectCreditRatingId === ""
            ? accessCreditRating.idAvaliacaoCreditoSalesforce
            : selectCreditRatingId,
        idCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
        renda: valueNumberFormat(values.income),
        idadeMaisVelho: Number(values.olderAge),
        prestacaoFinanciamento: valueNumberFormat(values.financingProvision),
        taxaBanco: Number(values.bankFee),
        flagNaoPodeVincularAc:
          valueRadioButton === "Preencher os dados da avaliação manualmente"
            ? false
            : true,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingUpdate(false);
        setError(true);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingUpdate(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage("Cotação editada com sucesso!");
      handleCloseModal();
      handleGetQuotesById();
      setLoadingUpdate(false);
    } catch (error) {
      setLoadingUpdate(false);
      console.log("ErrorHandleUpdateQuote", error);
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      setEdit(true);
    }
  }, [isEdit]);

  React.useEffect(() => {
    if (
      hashDetailOportunity &&
      (hash === "ViewQuotesComponent" ||
        successEditQuotes ||
        parcelamentoSalvo ||
        successCreateMensalPs)
    ) {
      handleGetQuotesById();
    }
  }, [
    hash,
    successEditQuotes,
    parcelamentoSalvo,
    successCreateMensalPs,
    hashDetailOportunity,
  ]);

  if (loading || loadingQuotes) {
    return (
      <Styled.LoadingContainer>
        <Spinner />
      </Styled.LoadingContainer>
    );
  }

  return (
    <>
      <MessageToast
        onOpen={error}
        message={errorMessage || "Erro ao carregar os dados da Cotação!"}
      />

      <AlertCompareValue
        onOpen={compareValue}
        value1={formatMoney(valorFinalKit)}
        value2={formatMoney(valorTotalUnidade)}
        titleButton={"Continuar mesmo assim"}
        onClick={() => {
          setShowModalUpdateDate(true);
          setCompareValue(false);
        }}
        onClose={() => setCompareValue(false)}
      />

      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Modal
        onOpen={showModalUpdateDate}
        title={
          currentIndex === 0
            ? "Atualizar dados do comprador principal"
            : "Atualizar dados do compositor de renda"
        }
        subtitle="Verifique se os dados estão corretos e atualizados para enviar a cotação"
        onClose={() => setShowModalUpdateDate(false)}
        relationship={relationship}
        currentIndexClient={currentIndex}
      >
        <UpdateDataClientComponent
          onBack={() => setShowModalUpdateDate(false)}
          onSuccess={handleSendToApproval}
          loadindSuccess={loadingApproval}
          relationship={relationship}
          setRelationship={setRelationship}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </Modal>

      <Modal
        onOpen={showCreateMensalPs}
        title="Criar Mensais PS"
        subtitle="Preencha as informações da parcela para adicionar na condição de pagamento."
        onClose={() => setShowCreateMensalPs(false)}
      >
        <CreateMensaPsComponent
          onBack={() => setShowCreateMensalPs(false)}
          setSuccessCreateMensalPs={setSuccessCreateMensalPs}
          maxQuantity={maxQuantity}
        />
      </Modal>

      <Modal
        onOpen={openCharts}
        title="Gráficos"
        subtitle=""
        onClose={() => setOpenCharts(false)}
      >
        <ViewChartsComponent />
      </Modal>

      <NotifyAC onOpen={showModalAc} onClose={handleCloseModal}>
        <Flex p={"6px"} flexDir={"column"}>
          {stepModal === 1 ? (
            <>
              <Flex>
                {valueRadioButton === "Vincular uma avaliação de crédito" ? (
                  <Flex flexDir={"column"}>
                    <Box h={"120px"}>
                      <InputSelectSearch
                        width="93%"
                        height="28%"
                        title="Avaliação de crédito:"
                        placeholder="Pesquise por avaliação"
                        value={selectCreditRating}
                        // searchOnClick
                        onChangeInput={(e) =>
                          setSelectCreditRating(e.target.value)
                        }
                        isSelected={selectCreditRating}
                        onChangeIcon={() => {
                          setSelectCreditRating("");
                          setCreditRating("");
                          if (!flagNaoPodeVincularAc) {
                            setSelectCreditRatingId("");
                          }
                        }}
                        onClickSearchIcon={handleGetCreditRatings}
                        loading={loadingCreditRating}
                      >
                        {creditRatingOptions}
                      </InputSelectSearch>
                    </Box>
                    <Flex gap={"10px"} mt={"20px"}>
                      <Button
                        error
                        title="Voltar"
                        onClick={() => {
                          setStepModal(0);
                          setValueRadioButton("");
                          handleDeleteAc();
                        }}
                      />
                      <Button
                        title={loadingUpdate ? <Spinner /> : "Avançar"}
                        onClick={handleGetCreditRatingById}
                        disabled={loadingUpdate || !selectCreditRatingId}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <>
                    <Flex flexDir={"column"}>
                      <Styled.ContainerBoxInputsCreditRating>
                        <InputTextEdit
                          label="Renda:"
                          name="income"
                          value={formik.values.income}
                          onChange={(e) => {
                            const { formattedValue } =
                              handleChangeMoneyDiscount(e);
                            formik.handleChange(e);
                            formik.setFieldValue("income", formattedValue);
                          }}
                        />

                        <InputText
                          label="Idade mais velho:"
                          name="olderAge"
                          value={formik.values.olderAge}
                          onChange={(e) => {
                            const newValue = handleChangeNumber(e);
                            formik.handleChange(e);
                            formik.setFieldValue("olderAge", newValue);
                          }}
                        />

                        <InputTextEdit
                          label="Prestação Financiamento:"
                          name="financingProvision"
                          value={formik.values.financingProvision}
                          onChange={(e) => {
                            const { formattedValue } =
                              handleChangeMoneyDiscount(e);
                            formik.handleChange(e);
                            formik.setFieldValue(
                              "financingProvision",
                              formattedValue
                            );
                          }}
                        />

                        <InputText
                          label="Taxa banco:"
                          name="bankFee"
                          value={formik.values.bankFee}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue(
                              "bankFee",
                              String(filterDecimalNumber(e.target.value))
                            );
                          }}
                        />
                      </Styled.ContainerBoxInputsCreditRating>
                      <Flex gap={"10px"} mt={"20px"}>
                        <Button
                          error
                          title="Voltar"
                          onClick={() => {
                            setStepModal(0);
                            formik.resetForm(initialValues);
                          }}
                        />
                        <Button
                          title={loadingUpdate ? <Spinner /> : "Avançar"}
                          onClick={formik.handleSubmit}
                          disabled={loadingUpdate}
                        />
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Flex gap={"10px"} flexDir={"column"}>
                <RadioSelect
                  label="Vincular uma avaliação de crédito"
                  checked={
                    valueRadioButton === "Vincular uma avaliação de crédito"
                  }
                  onChange={() =>
                    handleOptionChange("Vincular uma avaliação de crédito")
                  }
                />
                {!flagNaoPodeVincularAc && (
                  <RadioSelect
                    label="Preencher os dados da avaliação manualmente"
                    checked={
                      valueRadioButton ===
                      "Preencher os dados da avaliação manualmente"
                    }
                    onChange={() =>
                      handleOptionChange(
                        "Preencher os dados da avaliação manualmente"
                      )
                    }
                  />
                )}
              </Flex>
              <Flex gap={"10px"} mt={"20px"}>
                <Button error title="CANCELAR" onClick={handleCloseModal} />
                <Button
                  title={"Avançar"}
                  onClick={() => setStepModal(1)}
                  disabled={!valueRadioButton}
                />
              </Flex>
            </>
          )}
        </Flex>
      </NotifyAC>

      <Styled.ContainerTitlePage>
        <Flex>
          <Styled.TitlePage>{accessQuote.nomeDaCotacao}</Styled.TitlePage>
        </Flex>

        <Flex flexDir={"column"} w={"fit-content"} gap={"10px"}>
          <Flex gap={"10px"}>
            <Button
              title={"Inserir Avaliação de Crédito"}
              onClick={() => {
                if (disableEdit || disableApprove) {
                  return displayMessageError({
                    message:
                      "Não é possível editar uma cotação em análise/aprovada.",
                  });
                } else {
                  setShowModalAc(true);
                  setStepModal(0);
                }
              }}
            />
            <Button
              title={loadingApproval ? <Spinner /> : "Enviar para aprovação"}
              onClick={() => {
                if (statusQuote === "Em análise") {
                  displayMessageError({
                    message:
                      "Houve um erro ao atualizar a cotação. Infelizmente sua cotação não pôde ser enviada para aprovação, pois já existe uma cotação em análise nessa oportunidade, caso deseje enviar mesmo assim, solicite a rejeição da outra.",
                  });
                } else if (statusQuote === "Aprovada") {
                  displayMessageError({
                    message:
                      "Houve um erro ao atualizar a cotacao. Infelizmente sua cotação não pôde ser enviada para aprovação, pois já existe uma cotação aprovada nessa oportunidade.",
                  });
                } else if (valorFinalKit > valorTotalUnidade) {
                  setCompareValue(true);
                } else {
                  setShowModalUpdateDate(true);
                }
              }}
              // onClick={() => setShowModalUpdateDate(true)}
              disabled={loadingApproval || !emptyPortion}
            />
          </Flex>

          <Flex gap={"10px"} justifyContent={"flex-end"}>
            <Box w={"50px"}>
              <Button
                title={
                  <Styled.UpdateTitleButtons>
                    <RxUpdate />
                  </Styled.UpdateTitleButtons>
                }
                onClick={async () => {
                  try {
                    setLoading(true);
                    await handleGetCreditRatings();
                    await handleGetQuotesById();
                    setLoading(false);
                  } catch {
                    setLoading(false);
                  }
                }}
              />
            </Box>
            <Box w={"150px"}>
              <Button
                title={
                  <Styled.TitleButtons>
                    <MdOutlineEditNote />
                    Editar
                  </Styled.TitleButtons>
                }
                onClick={() => setEdit(true)}
                disabled={
                  edit ||
                  canEdit ||
                  faseOp ||
                  isSendToApproval ||
                  disableEdit ||
                  disableApprove
                }
              />
            </Box>
          </Flex>
          {/* <Styled.ContainerAnyButtons>
            <Styled.ContainerButtonUpdateHeader></Styled.ContainerButtonUpdateHeader>

            <Styled.ContainerButtonEditHeader></Styled.ContainerButtonEditHeader>
          </Styled.ContainerAnyButtons> */}
        </Flex>
      </Styled.ContainerTitlePage>

      <Styled.ContainerActivityCreditRating>
        <Styled.ContainerDetail>
          <Styled.ContainerAccordion>
            <AccordionQuotesComponent
              formik={formik}
              edit={edit}
              flagNaoPodeVincularAc={flagNaoPodeVincularAc}
              creditRating={creditRating}
              setCreditRating={setCreditRating}
              selectCreditRating={selectCreditRating}
              selectCreditRatingId={selectCreditRatingId}
              setSelectCreditRating={setSelectCreditRating}
              loadingCreditRating={loadingCreditRating}
              getCreditRating={handleGetCreditRatings}
              dataCreditRating={dataCreditRating}
              setSelectCreditRatingId={setSelectCreditRatingId}
              disabledIncome={disabledIncome}
              bonusAdimplencia={bonusAdimplencia}
            />
          </Styled.ContainerAccordion>
        </Styled.ContainerDetail>

        {edit ? (
          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={handleCancelButton}
                disabled={!edit}
              />

              <Button
                title={loadingUpdate ? <Spinner /> : "SALVAR"}
                onClick={formik.handleSubmit}
                disabled={loadingUpdate || !edit}
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        ) : (
          <>
            <Styled.ContainerQuotes>
              <Styled.ContainerHeaderQuotes>
                <Styled.ContainerTitleRelationship>
                  <span>Condição de pagamento</span>
                </Styled.ContainerTitleRelationship>
                {/* <Styled.ButtonTitleRelationship>
                  <Button
                    title={
                      <Styled.TitleButtons>
                        <BiPlus />
                        GRÁFICOS
                      </Styled.TitleButtons>
                    }
                    onClick={() => {
                      setOpenCharts(true);
                    }}
                  />
                </Styled.ButtonTitleRelationship> */}
                {isAssociative && (
                  <Styled.ButtonTitleRelationship>
                    <Button
                      title={
                        <Styled.TitleButtons>
                          <BiPlus />
                          MENSAL PS
                        </Styled.TitleButtons>
                      }
                      onClick={() => {
                        if (disabledFinancingProvision) {
                          displayMessageError({
                            message: `Campo "Prestação Financiamento" obrigatório! Preencha no menu Valores.`,
                          });
                        } else {
                          setShowCreateMensalPs(true);
                        }
                      }}
                      disabled={
                        existMensalPs ||
                        isSendToApproval ||
                        disableEdit ||
                        disableApprove ||
                        !parcelamentoSalvo ||
                        parcelaAdicionadaNaoSalva
                      }
                    />
                  </Styled.ButtonTitleRelationship>
                )}
              </Styled.ContainerHeaderQuotes>

              <Styled.ContainerTableQuotes>
                <TablePortion
                  data={dataQuotes}
                  discount={discount}
                  setDiscount={setDiscount}
                  successCreateMensalPs={successCreateMensalPs}
                  setExistMensalPs={setExistMensalPs}
                  setEmptyPortion={setEmptyPortion}
                  disabledIncome={disabledIncome}
                  disableEdit={disableEdit}
                  disabledStatusApprove={disableApprove}
                  onSalvarParcelamento={handleSalvarParcelamento}
                  onAdicionarParcela={handleAdicionarParcela}
                  bonusAdimplencia={bonusAdimplencia}
                />
              </Styled.ContainerTableQuotes>
            </Styled.ContainerQuotes>

            <Styled.ContainerQuotes>
              <Styled.ContainerHeaderQuotes>
                <Styled.ContainerTitleRelationship>
                  <span>Análise da Proposta</span>
                </Styled.ContainerTitleRelationship>
              </Styled.ContainerHeaderQuotes>

              <Styled.ContainerTableQuotes>
                <TableProposalAnalysis
                  dataProposalAnalysis={dataProposalAnalysis}
                />
              </Styled.ContainerTableQuotes>
            </Styled.ContainerQuotes>
          </>
        )}
      </Styled.ContainerActivityCreditRating>
    </>
  );
};

export default ViewQuotesComponent;
