import styled from "styled-components";
import { theme } from "../../styles/theme";
import { device } from "../../styles/variable";

export const Area = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: ${theme.backgroundSecondary};
  overflow: hidden;

  @media ${device.mobileL} {
    background: none;
  }
`;

export const AreaLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @media ${device.tablet} {
    display: none;
    width: none;
  }
  @media ${device.mobileL} {
    display: none;
    width: none;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 100%;

  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
  }
  @media ${device.tablet} {
    width: 80%;
    height: 100%;
  }
`;

export const AreaRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const ContainerRightLogo = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-self: center;
  justify-content: center;

  > img {
    width: 160px;
    height: auto;
    object-fit: contain;
  }
`;

export const BodyRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BodyRightTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  font-weight: bold;
  > span {
    color: ${theme.text};
    font-size: 17px;
  }

  @media ${device.laptopL} {
    margin-top: 8px;
  }
`;

export const BodyRightSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
  height: fit-content;

  > span {
    color: ${theme.text};
    font-size: 11px;
    > strong {
      color: black;
    }
  }
`;

export const ContainerRadioButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 42%;
  margin-top: 20px;

  > div {
    margin-bottom: 5px;
  }

  @media ${device.mobileL} {
    height: 35%;
  }
`;

export const ContainerRadioButtonBody = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  margin-top: 5px;

  > span {
    font-size: 10px;
    line-height: 0.9rem;
    color: ${theme.text};

    > strong {
      color: black;
    }
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  border: 2px solid ${theme.disable};
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 5px;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 11px;
    color: ${theme.text};
    line-height: 0.9rem;

    > strong {
      color: black;
    }
  }
  @media ${device.laptopXL} {
    min-height: 12%;
  }
  @media ${device.tablet} {
    padding: 6px 16px;
    margin-top: 0px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin-top: 16px;
`;

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContainerTabs = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
