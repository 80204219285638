import React, { useState } from "react";
import { IValidationDetailOportuntySchema } from "src/@types/Oportunity";
import { IOportunityContext } from "src/@types/context/oportunityContext.structure";
import {
  IOnboardingDocument,
  IContractData,
} from "@services/Oportunity/OportunityService";

interface IProps {
  children: React.ReactNode;
}

export const OportunityContext = React.createContext({} as IOportunityContext);

const OportunityContextProvider: React.FC<IProps> = ({ children }) => {
  const [opportunityIdSalesforce, setOpportunityIdSalesforce] =
    React.useState("");
  const [accountIdSalesforce, setAccountIdSalesforce] = React.useState("");
  const [opportunityId, setOpportunityId] = React.useState("");
  const [opportunityName, setOpportunityName] = React.useState("");
  const [origemConta, setOrigemConta] = React.useState("");
  const [createTaskOrCall, setCreateTaskOrCall] = React.useState("");
  const [dataDetailOportunity, setDataDetailOportunity] =
    React.useState<IValidationDetailOportuntySchema>();
  const [onboardingDocuments, setOnboardingDocuments] = React.useState<
    IOnboardingDocument[]
  >([]);
  const [contracts, setContracts] = React.useState<IContractData[]>([]);
  const [loading, setLoading] = useState(false);
  const [vendaFutura, setVendaFutura] = React.useState(false);
  const [imobiliariaNome, setImobiliariaNome] = React.useState("");
  const [imobiliariaId, setImobiliariaId] = React.useState("");
  const [idEmpreendimentoSales, setIdEmpreendimentoSales] = React.useState("");
  const [nomeEmpreendimentoSales, setNomeEmpreendimentoSales] =
    React.useState("");
  const [nomeConta, setNomeConta] = React.useState("");
  const [idAvaliacaoCreditoSalesforce, setIdAvaliacaoCreditoSalesforce] =
    React.useState("");
  const [nameCreditRating, setNameCreditRating] = React.useState("");
  const [successCreateTask, setSuccessCreateTask] = React.useState(false);
  const [successCreateSchedule, setSuccessCreateSchedule] =
    React.useState(false);
  const [statusCreditRating, setStatusCreditRating] = React.useState("");
  const [faseOp, setFaseOp] = React.useState(false);
  const [paymentCondition, setPaymentCondition] = React.useState("");
  const [isSendToApproval, setIsSendToApproval] = React.useState(false);
  const [currentFase, setCurrentFase] = React.useState("");
  const [disabledQuote, setDisabledQuote] = React.useState(false);
  const [statusQuote, setStatusQuote] = React.useState("");
  const [cnpjCorresponding, setCnpjCorresponding] = React.useState();
  const [idRelacionamentoComprador, setIdRelacionamentoComprador] =
    React.useState("");
  const [loadingDeleteRelationship, setLoadingDeleteRelationship] =
    React.useState(false);
  const [isUpdateAdress, setIsUpdateAdress] = React.useState(false);
  const [isUpdateAdressIncome, setIsUpdateAdressIncome] = React.useState(false);
  const [allowSendToAnalisys, setAllowSendToAnalisys] = React.useState(true);
  const [successUpdateAddresModal, setSuccessUpdateAddresModal] =
    React.useState(false);
  const [emptyAdress, setEmptyAdress] = React.useState(false);
  const [requireAdress, setRequireAdress] = React.useState(false);
  const [reloadSucessAdress, setReloadSucessAdress] = React.useState(false);

  const [accessCreateOp, setAccessCreateOp] = React.useState({
    oportunidadeId: "",
    oportunityIdSalesforce: "",
    nomeDaConta: "",
  });
  const [accessCreditRating, setAccessCreditRating] = React.useState({
    idAvaliacaoCreditoSalesforce: "",
    nome: "",
  });
  const [accessRelationship, setAccessRelationship] = React.useState({
    nomeConta: "",
    codigoRelacionamentoComComprador: "",
  });
  const [accessTaskCalls, setAccessTasksCalls] = React.useState({
    idTarefaSalesforce: "",
    tipoDeRegistro: "",
  });
  const [accessSchedule, setAccessSchedule] = React.useState({
    idAgendamentoSalesforce: "",
    codigoDoAgendamento: "",
    visitaRealizada: false,
  });
  const [accessQuote, setAccessQuote] = React.useState({
    idCotacaoSalesforce: "",
    nomeDaCotacao: "",
  });
  const [accessAttachments, setAccessAttachments] = React.useState({
    codigoRelacionamentoComComprador: "",
    cpf: "",
  });

  const [discount, setDiscount] = React.useState("");
  const [disabledCreateDocument, setDisabledCreateDocument] =
    React.useState(false);

  const valuesOportunity = React.useMemo(
    () => ({
      statusQuote,
      setStatusQuote,
      disabledCreateDocument,
      setDisabledCreateDocument,
      accessAttachments,
      setAccessAttachments,
      reloadSucessAdress,
      setReloadSucessAdress,
      emptyAdress,
      setEmptyAdress,
      requireAdress,
      setRequireAdress,
      successUpdateAddresModal,
      setSuccessUpdateAddresModal,
      isUpdateAdress,
      setIsUpdateAdress,
      disabledQuote,
      setDisabledQuote,
      currentFase,
      setCurrentFase,
      accountIdSalesforce,
      setAccountIdSalesforce,
      opportunityIdSalesforce,
      setOpportunityIdSalesforce,
      opportunityId,
      setOpportunityId,
      origemConta,
      setOrigemConta,
      opportunityName,
      setOpportunityName,
      createTaskOrCall,
      setCreateTaskOrCall,
      dataDetailOportunity,
      setDataDetailOportunity,
      onboardingDocuments,
      setOnboardingDocuments,
      contracts,
      setContracts,
      loading,
      setLoading,
      vendaFutura,
      setVendaFutura,
      imobiliariaId,
      setImobiliariaId,
      idEmpreendimentoSales,
      setIdEmpreendimentoSales,
      imobiliariaNome,
      setImobiliariaNome,
      nomeEmpreendimentoSales,
      setNomeEmpreendimentoSales,
      nomeConta,
      setNomeConta,
      idAvaliacaoCreditoSalesforce,
      setIdAvaliacaoCreditoSalesforce,
      nameCreditRating,
      setNameCreditRating,
      accessCreditRating,
      setAccessCreditRating,
      accessSchedule,
      setAccessSchedule,
      accessTaskCalls,
      setAccessTasksCalls,
      accessCreateOp,
      setAccessCreateOp,
      successCreateTask,
      setSuccessCreateTask,
      successCreateSchedule,
      setSuccessCreateSchedule,
      accessRelationship,
      setAccessRelationship,
      statusCreditRating,
      setStatusCreditRating,
      faseOp,
      setFaseOp,
      accessQuote,
      setAccessQuote,
      paymentCondition,
      setPaymentCondition,
      isSendToApproval,
      setIsSendToApproval,
      cnpjCorresponding,
      setCnpjCorresponding,
      allowSendToAnalisys,
      setAllowSendToAnalisys,
      idRelacionamentoComprador,
      setIdRelacionamentoComprador,
      loadingDeleteRelationship,
      setLoadingDeleteRelationship,
      isUpdateAdressIncome,
      setIsUpdateAdressIncome,
      discount,
      setDiscount,
    }),
    [
      statusQuote,
      setStatusQuote,
      disabledCreateDocument,
      setDisabledCreateDocument,
      accessAttachments,
      setAccessAttachments,
      reloadSucessAdress,
      setReloadSucessAdress,
      emptyAdress,
      setEmptyAdress,
      requireAdress,
      setRequireAdress,
      successUpdateAddresModal,
      setSuccessUpdateAddresModal,
      isUpdateAdress,
      setIsUpdateAdress,
      disabledQuote,
      setDisabledQuote,
      currentFase,
      accountIdSalesforce,
      opportunityIdSalesforce,
      opportunityId,
      origemConta,
      opportunityName,
      createTaskOrCall,
      dataDetailOportunity,
      onboardingDocuments,
      contracts,
      loading,
      vendaFutura,
      imobiliariaId,
      idEmpreendimentoSales,
      imobiliariaNome,
      nomeEmpreendimentoSales,
      nomeConta,
      idAvaliacaoCreditoSalesforce,
      nameCreditRating,
      accessCreditRating,
      accessSchedule,
      accessTaskCalls,
      accessCreateOp,
      successCreateTask,
      successCreateSchedule,
      accessRelationship,
      statusCreditRating,
      faseOp,
      accessQuote,
      paymentCondition,
      isSendToApproval,
      cnpjCorresponding,
      setCnpjCorresponding,
      allowSendToAnalisys,
      idRelacionamentoComprador,
      setIdRelacionamentoComprador,
      loadingDeleteRelationship,
      setLoadingDeleteRelationship,
      isUpdateAdressIncome,
      setIsUpdateAdressIncome,
      discount,
      setDiscount,
    ]
  );

  return (
    <OportunityContext.Provider value={valuesOportunity}>
      {children}
    </OportunityContext.Provider>
  );
};

export default OportunityContextProvider;
