import React from "react";
import * as Styled from "../stylesView&Create";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import oportunityService from "@services/Oportunity/OportunityService";
import { optionBankCreditRating } from "@helpers/optionSelect";
import InputText from "@components/Sales/Inputs/InputText";
import { OportunityContext } from "@context/oportunityContex";
import enterprisesServiceInstance from "@services/Enterprises/enterprisesService";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import {
  optionCorresponding,
  optionEnterprise,
} from "../../components/options";
import { useMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { ICreateCreditRatingComponent } from "src/@types/Sales";

const optionAcAtiva = [
  {
    id: "sim",
    name: "Sim",
  },
  {
    id: "não",
    name: "Não",
  },
];

const CreateCreditRatingComponent = ({
  onBack,
  setSuccessCreateCreditRating,
  setHash,
}: ICreateCreditRatingComponent) => {
  const regionalUser: any = localStorage.getItem("@regional");
  const regiaolUser: any = localStorage.getItem("@regiao");
  const unityUser: any = localStorage.getItem("@empresa");
  const codEmpreendimentoUAU: any = localStorage.getItem(
    "@codEmpreendimentoUAU"
  );
  const displayMessage = useMessageToast();
  const displayMessageError = useMessageErrorToast();
  const {
    accountIdSalesforce,
    vendaFutura,
    imobiliariaId,
    idEmpreendimentoSales,
    nomeEmpreendimentoSales,
    imobiliariaNome,
    nomeConta,
    setAccessCreditRating,
    accessCreateOp,
  } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const [estimatedPurchaseValue, setEstimatedPurchaseValue] =
    React.useState("");
  const [bank, setBank] = React.useState("");
  const [acActivity, setAcActivity] = React.useState("Sim");
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [observations, setObservations] = React.useState("");
  const [require, setRequire] = React.useState(false);
  const [loadingEnterprise, setLoadingEnterprise] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [idEnterprises, setIdEnterprises] = React.useState(
    idEmpreendimentoSales || ""
  );
  const [enterprises, setEnterprises] = React.useState("");
  const [selectEnterprises, setSelectEnterprises] = React.useState("");
  const [corresponding, setCorresponding] = React.useState("");
  const [correspondingId, setCorrespondingId] = React.useState("");
  const [selectCorresponding, setSelectCorresponding] = React.useState("");
  const [loadingCorresponding, setLoadingCorresponding] = React.useState(false);
  const [dataCorresponding, setDataCorresponding] = React.useState([]);
  const [loadingCorrespondingRow, setLoadingCorrespondingRow] =
    React.useState(false);
  const [isCorresponding, setIsCorresponding] = React.useState(true);

  const renderedOptionsCorresponding = optionCorresponding(
    dataCorresponding,
    selectCorresponding,
    setSelectCorresponding,
    setCorresponding,
    setCorrespondingId
  );

  const handleGetCorresponding = async () => {
    setLoadingCorresponding(true);

    try {
      const response = await oportunityService.getCorresponding({
        oportunidadeIdSalesforce: accessCreateOp.oportunityIdSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCorresponding(false);
        setDataCorresponding([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCorresponding(false);
        setIsUnauthorized(true);
        setDataCorresponding([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataCorresponding(dataArray);
      setLoadingCorresponding(false);
    } catch (error) {
      setLoadingCorresponding(false);
      console.log("errorHandleGetCorresponding", error);
    }
  };

  const handleCreateCreditRating = async () => {
    setLoading(true);
    setSuccessCreateCreditRating(false);

    const correspondente: any = dataCorresponding.find(
      (item: any) => item.nomeCorrespondente === selectCorresponding
    );

    try {
      const response = await oportunityService.createCreditRating({
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        idEmpreendimentoSales: idEnterprises,
        accountIdSalesforce,
        imobiliariaId,
        vendaFutura: vendaFutura,
        acAtiva: acActivity === "Sim" ? true : false,
        nomeBanco: bank,
        observacoes: observations,
        valorEstimadoCompraEVenda: estimatedPurchaseValue,
        nomeCorrespondente: selectCorresponding,
        cnpjCorrespondente: correspondente?.cnpj,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({ message: "Erro ao criar avaliação de crédito!" });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      displayMessage({
        title: "Atividade registrada com sucesso!",
        message:
          "A nova atividade foi criada e você já pode acessar para continuar sua venda.",
        titleButton: "ACESSAR AVALIAÇÃO DE CRÉDITO",
        onClick: () => {
          setHash("ViewCreditRatingComponent");
          setSuccessCreateCreditRating(false);
        },
      });
      setSuccessCreateCreditRating(true);
      onBack();
      setAccessCreditRating({
        idAvaliacaoCreditoSalesforce: response.idAvaliacaoCreditoSalesforce,
        nome: response.nome,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleCreateCreditRating", error);
    }
  };

  const formatMoney = (value: string) => {
    let formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2")
      .replace(/(?=(\d{3})+(\D))\B/g, ".");

    formattedValue = `R$ ${formattedValue}`;

    return formattedValue;
  };

  const handleGetEnterprises = async () => {
    setLoadingEnterprise(true);

    try {
      const response = await enterprisesServiceInstance.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprise(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprise(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprise(false);
    } catch (error) {
      setLoadingEnterprise(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectEnterprises,
    setSelectEnterprises,
    setEnterprises,
    setIdEnterprises
  );

  React.useEffect(() => {
    const handleGetCorrespondingRow = async () => {
      setLoadingCorrespondingRow(true);

      try {
        const response = await oportunityService.getCorrespondingRow({
          idEmpreendimento: idEnterprises,
          regional: regionalUser,
          unidadeDeNegocio: unityUser,
          regiao:
            regiaolUser === "null" || regiaolUser === "undefined"
              ? null
              : regiaolUser,
        });

        if (response.status >= 400 && response?.status !== 401) {
          setLoadingCorrespondingRow(false);
          console.log("status 400", response);
          return;
        }
        if (response.status === 401) {
          setLoadingCorrespondingRow(false);
          setIsUnauthorized(true);
          return;
        }

        setIsCorresponding(response);
        setLoadingCorrespondingRow(false);
      } catch (error) {
        setLoadingCorrespondingRow(false);
        console.log("errorHandleGetCorrespondingRow", error);
      }
    };

    if (enterprises) {
      handleGetCorrespondingRow();
    }
  }, [enterprises, idEnterprises, regiaolUser, regionalUser, unityUser]);

  React.useEffect(() => {
    if (!isCorresponding) {
      const isRequirement =
        !corresponding ||
        !estimatedPurchaseValue ||
        !bank ||
        !nomeConta ||
        !imobiliariaNome ||
        !accessCreateOp.oportunityIdSalesforce ||
        !acActivity ||
        !enterprises;

      setRequire(isRequirement);
    } else {
      const isRequirement =
        !estimatedPurchaseValue ||
        !bank ||
        !nomeConta ||
        !imobiliariaNome ||
        !accessCreateOp.oportunityIdSalesforce ||
        !acActivity ||
        !enterprises;
      setRequire(isRequirement);
    }
  }, [
    corresponding,
    accessCreateOp,
    imobiliariaNome,
    nomeConta,
    enterprises,
    acActivity,
    estimatedPurchaseValue,
    bank,
    isCorresponding,
  ]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingCorrespondingRow ? (
        <Styled.LoadingSpinner>
          <Spinner />
        </Styled.LoadingSpinner>
      ) : (
        <>
          <Styled.ContainerSchedule>
            <Styled.TitleSchedule>
              Nova avaliação de crédito:
            </Styled.TitleSchedule>

            <Styled.ContainerInputsGridDuo>
              <InputSelect
                isRequirement
                widht="46%"
                title="Banco:"
                label={bank || "Selecione uma opção"}
                isSelected={bank}
                options={optionBankCreditRating}
                selectedOption={bank}
                onChange={(e) => setBank(e.currentTarget.value)}
              />

              <InputText
                isRequirement
                label="Valor estimado de compra e venda:"
                placeholder="Valor estimado de compra e venda"
                name="Valor estimado de compra e venda"
                value={estimatedPurchaseValue}
                onChange={(e) =>
                  setEstimatedPurchaseValue(formatMoney(e.target.value))
                }
              />
            </Styled.ContainerInputsGridDuo>

            <Styled.ContainerInputsGridDuo>
              <InputText
                isRequirement
                readOnly
                label="Conta:"
                placeholder="Conta"
                name="nomeConta"
                value={nomeConta}
              />

              <InputSelectSearch
                isRequirement
                width="46%"
                height="24%"
                title="Empreendimento:"
                placeholder="Pesquise por empreendimento"
                value={selectEnterprises}
                onChangeInput={(e) => setSelectEnterprises(e.target.value)}
                isSelected={enterprises}
                onChangeIcon={() => {
                  setEnterprises("");
                  setSelectEnterprises("");
                  setCorresponding("");
                  setSelectCorresponding("");
                  setIsCorresponding(true);
                }}
                onClickSearchIcon={handleGetEnterprises}
                loading={loadingEnterprise}
              >
                {renderedOptions}
              </InputSelectSearch>
            </Styled.ContainerInputsGridDuo>

            <Styled.ContainerInputsGridDuo>
              <InputText
                isRequirement
                readOnly
                label="Imobiliária:"
                placeholder="Imobiliária"
                name="imobiliariaNome"
                value={imobiliariaNome}
              />

              <InputText
                isRequirement
                readOnly
                label="Oportunidade:"
                placeholder="Oportunidade"
                name="opportunityIdSalesforce"
                value={`${accessCreateOp.nomeDaConta}(${accessCreateOp.oportunidadeId})`}
              />
            </Styled.ContainerInputsGridDuo>

            <Styled.ContainerInputsGridDuo>
              <InputSelect
                isRequirement
                widht="46%"
                title="AC ativa:"
                label={acActivity || "Selecione uma opção"}
                isSelected={acActivity}
                options={optionAcAtiva}
                selectedOption={acActivity}
                onChange={(e) => setAcActivity(e.currentTarget.value)}
              />

              {!isCorresponding && (
                <InputSelectSearch
                  disabled={!enterprises}
                  isRequirement
                  width="46%"
                  height="28%"
                  title="Correspondente:"
                  placeholder="Pesquise por correspondente"
                  value={selectCorresponding}
                  onChangeInput={(e: any) =>
                    setSelectCorresponding(e.target.value)
                  }
                  isSelected={corresponding}
                  onChangeIcon={() => {
                    setCorresponding("");
                    setSelectCorresponding("");
                  }}
                  onClickSearchIcon={handleGetCorresponding}
                  loading={loadingCorresponding}
                >
                  {renderedOptionsCorresponding}
                </InputSelectSearch>
              )}
            </Styled.ContainerInputsGridDuo>

            <Styled.ContainerInputsGridSolo>
              <InputTextArea
                label="Observações:"
                placeholder="Observações"
                name="Observações"
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
            </Styled.ContainerInputsGridSolo>
          </Styled.ContainerSchedule>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooter>
              <Button error title="CANCELAR" onClick={onBack} />

              <Button
                title={loading ? <Spinner /> : "SALVAR"}
                onClick={handleCreateCreditRating}
                disabled={require || loading}
              />
            </Styled.ContainerButtonFooter>
          </Styled.ContainerFooterBox>
        </>
      )}
    </>
  );
};

export default CreateCreditRatingComponent;
