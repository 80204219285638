import React from "react";
import { capitalize } from "@helpers/format";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface ISuccessAccessComponent {
  name: string;
  loadingLogin: boolean;
  handleLogin: () => void;
}

const SuccessAccessComponent = ({
  loadingLogin,
  name,
  handleLogin,
}: ISuccessAccessComponent) => {
  return (
    <Flex flexDir={"column"} align={"center"} justify={"center"} h={"400px"}>
      <Flex
        flexDir={"column"}
        w={"75%"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={"20px"}
        textAlign={"center"}
      >
        <Text
          fontSize={"18px"}
          w={"fit-content"}
          color={theme.text}
          fontWeight={"medium"}
          mb={"20px"}
        >
          Cadastro realizado com sucesso!
        </Text>
        <Text fontSize={"13px"} color={theme.text} mt={"8px"}>
          {capitalize(name)}, agora você já{" "}
          <strong>pode aproveitar todas as funcionalidades</strong> do portal
          Bora Vender!
        </Text>

        <Flex mt={"15px"}>
          <Text fontSize={"13px"} color={theme.text}>
            Não se esqueça de manter suas informações de perfil sempre
            atualizadas.
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDir={"column"}
        w={"75%"}
        justify={"center"}
        align={"center"}
        mb={"25px"}
      >
        <Button
          title={loadingLogin ? <Spinner /> : "Acessar o portal"}
          onClick={handleLogin}
          disabled={loadingLogin}
        />
      </Flex>
    </Flex>
  );
};

export default SuccessAccessComponent;
