import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { requirementsRadio } from "@helpers/securePassword";
import { Box, Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { BsCheck } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import InputPassword from "@components/Login/Inputs/InputPassword";
import Helper from "@components/Login/Helper";

interface ICreatePasswordComponent {
  newPassword: string;
  setNewPassword: any;
  validateInput: any;
  errorRadio: any;
  loading: boolean;
  handleEnter: () => void;
}

const CreatePasswordComponent = ({
  newPassword,
  setNewPassword,
  validateInput,
  errorRadio,
  loading,
  handleEnter,
}: ICreatePasswordComponent) => {
  const handleValidPassword = (checked: boolean, error: boolean) => {
    if (newPassword !== "" && checked === false && error === false) {
      return (
        <Box
          w={"fit-content"}
          h={"fit-content"}
          color={theme.primary}
          fontSize={"16px"}
        >
          <BsCheck />
        </Box>
      );
    } else if (error && checked) {
      return (
        <Box
          w={"fit-content"}
          h={"fit-content"}
          color={theme.error}
          fontSize={"16px"}
        >
          <IoIosClose />
        </Box>
      );
    } else {
      return (
        <Box
          w={"15px"}
          h={"13px"}
          borderRadius={"full"}
          bg={theme.backgroundSecondary}
        />
      );
    }
  };
  const handleValidPasswordColorText = (checked: boolean, error: boolean) => {
    if (newPassword !== "" && checked === false && error === false) {
      return theme.primary;
    } else if (error && checked) {
      return theme.error;
    } else {
      return theme.text50;
    }
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={"20px"}
        textAlign={"center"}
      >
        <Text
          fontSize={"18px"}
          w={"fit-content"}
          color={theme.text}
          fontWeight={"medium"}
        >
          Crie uma nova senha
        </Text>
      </Flex>

      <Flex flexDir={"column"} w={"75%"} h={"30%"} align={"center"}>
        <Flex flexDir={"column"}>
          <InputPassword
            isLabel
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value.replace(/\D/g, ""));
            }}
            error={!!newPassword && errorRadio}
          />
        </Flex>
      </Flex>

      <Flex flexDir={"column"} w={"75%"} align={"center"}>
        {requirementsRadio(validateInput).map((requirement, index) => (
          <Flex
            key={requirement.title}
            mb={"10px"}
            align={"center"}
            gap={"10px"}
          >
            {handleValidPassword(requirement.checked, requirement.error)}

            <Text
              fontSize={"13px"}
              color={handleValidPasswordColorText(
                requirement.checked,
                requirement.error
              )}
            >
              {requirement.title}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Flex
        flexDir={"column"}
        w={"75%"}
        justify={"center"}
        align={"center"}
        mb={"25px"}
      >
        <Button
          title={!loading ? "Confirmar" : <Spinner />}
          onClick={handleEnter}
          disabled={loading || !!errorRadio || !newPassword}
        />
      </Flex>

      <Helper />
    </>
  );
};

export default CreatePasswordComponent;
