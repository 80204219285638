import React from "react";
import * as Styled from "../../Steps/stylesStepTab";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { cepMask } from "@helpers/format";
import cepServiceInstance from "@services/Cep/cepService";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import Spinner from "@components/common/Spinner";
import { IUpdateAdress, RelationshipAdress } from "src/@types/Sales";
import { optionUF } from "@helpers/optionSelect";
import salesServiceInstance from "@services/Sales/SalesService";
import { OportunityContext } from "@context/oportunityContex";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";

type Props = {
  relationship: RelationshipAdress;
  accountIdSalesforce: string;
  edit: boolean;
  isModalUpdate?: boolean;
  setLoadingUpdateAdress: React.Dispatch<React.SetStateAction<boolean>>;
  isClicked: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  isCancel?: boolean;
  setIsCancel?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdressClientComponent = ({
  relationship,
  accountIdSalesforce,
  edit,
  isModalUpdate,
  setLoadingUpdateAdress,
  isClicked,
  setIsClicked,
  onSuccess,
  isCancel,
  setIsCancel = () => {},
}: Props) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const {
    isUpdateAdress,
    setRequireAdress,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
    accessCreateOp,
  } = React.useContext(OportunityContext);
  const [loadingCep, setLoadingCep] = React.useState(false);

  const [residentialAddress, setResidentialAddress] = React.useState({
    idEnderecoSalesforce: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const [correspondenceAddress, setCorrespondenceAddress] = React.useState({
    idEnderecoSalesforce: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
  });

  const [checkAdress, setCheckAdress] = React.useState(true);

  const [newRelationship, setNewRelationship] =
    React.useState<RelationshipAdress>();

  // const handleName = () => {
  //   const compradorPrincipal = relationship?.find(
  //     (rel) => rel?.compradorPrincipal
  //   );
  //   return compradorPrincipal ? compradorPrincipal?.nome : "";
  // };

  const handleDataMainBuyer = relationship;

  const handleNewDataMainBuyer = newRelationship;

  const handleGetOportunity = async () => {
    try {
      const response = await oportunityServiceInstance.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });

      if (response.status === 401) {
        return;
      }

      setNewRelationship(response?.relacionamentosComprador);
    } catch (error) {
      console.log("ErrorHandleGetOportunityById", error);
    }
  };

  const handleUpdateAdress = async () => {
    setLoadingUpdateAdress(true);

    setIsClicked(false);
    try {
      const body: IUpdateAdress[] = [
        {
          idEnderecoSalesforce: residentialAddress?.idEnderecoSalesforce,
          accountIdSalesforce,
          cep: residentialAddress.cep,
          numero: residentialAddress.numero,
          bairro: residentialAddress.bairro,
          logradouro: residentialAddress.logradouro,
          uf: residentialAddress.uf,
          complemento: residentialAddress.complemento,
          cidadeSAFI: residentialAddress.cidade,
          cidade: residentialAddress.cidade,
          tipoDeRegistro: "Residencial",
        },
        // Condicionalmente adicionar o endereço de correspondência
        ...(checkAdress
          ? [
              {
                idEnderecoSalesforce:
                  correspondenceAddress?.idEnderecoSalesforce,
                accountIdSalesforce,
                cep: residentialAddress.cep,
                numero: residentialAddress.numero,
                bairro: residentialAddress.bairro,
                logradouro: residentialAddress.logradouro,
                uf: residentialAddress.uf,
                complemento: residentialAddress.complemento,
                cidadeSAFI: residentialAddress.cidade,
                cidade: residentialAddress.cidade,
                tipoDeRegistro: "Correspondência",
              },
            ]
          : [
              {
                idEnderecoSalesforce:
                  correspondenceAddress?.idEnderecoSalesforce || "",
                accountIdSalesforce,
                cep: correspondenceAddress.cep,
                numero: correspondenceAddress.numero,
                bairro: correspondenceAddress.bairro,
                logradouro: correspondenceAddress.logradouro,
                uf: correspondenceAddress.uf,
                complemento: correspondenceAddress.complemento,
                cidadeSAFI: correspondenceAddress.cidade,
                cidade: correspondenceAddress.cidade,
                tipoDeRegistro: "Correspondência",
              },
            ]),
      ];

      if (
        handleDataMainBuyer?.enderecos[0]?.length === 0 ||
        residentialAddress.cep === ""
      ) {
        setIsClicked(false);
        setIsUpdateAdressIncome(true);
        setIsUpdateAdress(false);
        return;
      }

      const response = await salesServiceInstance.updateAdress(body);

      if (response?.data?.sucesso === false) {
        setLoadingUpdateAdress(false);
        setIsClicked(false);
        displayMessageError({ message: response?.data?.mensagem });
        setIsUpdateAdressIncome(false);
        setIsUpdateAdress(false);
        return;
      } else {
        // setIsUpdateAdress(false);
        // setIsUpdateAdressIncome(true);
        setLoadingUpdateAdress(false);
        setIsClicked(false);
        // handleGetOportunity();
        onSuccess();
      }
    } catch (error) {
      setLoadingUpdateAdress(false);
      console.log("ErrorHandleUpdateAdress", error);
    }
  };

  const handleCepChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setAddress: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const newCep = cepMask(e.target.value);

    setAddress((prevAddress: any) => ({
      ...prevAddress,
      cep: newCep,
    }));

    if (newCep.length === 9) {
      handleGetCep(newCep, setAddress);
    }
  };

  const handleCepComposerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setAddress: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const newCep = e.target.value;

    setAddress((prevAddress: any) => ({
      ...prevAddress,
      cep: cepMask(newCep),
    }));

    if (!checkAdress && cepMask(newCep).length === 9) {
      const handleGetCepCorrespondence = async () => {
        await handleGetCep(cepMask(newCep), setCorrespondenceAddress);
      };

      handleGetCepCorrespondence();
    }
  };

  const handleGetCep = async (cep: string, setAddress: any) => {
    setLoadingCep(true);
    try {
      const response = await cepServiceInstance.getCep({ cep });

      if (response.status === 401) {
        setLoadingCep(false);
        return;
      }

      setAddress((prevAddress: any) => ({
        ...prevAddress,
        logradouro: response?.street || "",
        bairro: response?.neighborhood || "",
        uf: response?.state || "",
        cidade: response?.city || "",
      }));

      setLoadingCep(false);
    } catch (error) {
      setLoadingCep(false);
      console.log("ErrorHandleGetCep", error);
    }
  };

  React.useEffect(() => {
    const newResidential =
      !residentialAddress?.cep ||
      !residentialAddress?.logradouro ||
      !residentialAddress?.numero ||
      !residentialAddress?.bairro ||
      !residentialAddress?.uf ||
      !residentialAddress?.cidade;

    const newCorrespondence =
      (!checkAdress && !correspondenceAddress?.cep) ||
      (!checkAdress && !correspondenceAddress?.logradouro) ||
      (!checkAdress && !correspondenceAddress?.numero) ||
      (!checkAdress && !correspondenceAddress?.bairro) ||
      (!checkAdress && !correspondenceAddress?.uf) ||
      (!checkAdress && !correspondenceAddress?.cidade);

    setRequireAdress(newResidential || newCorrespondence);
  }, [
    checkAdress,
    correspondenceAddress?.bairro,
    correspondenceAddress?.cep,
    correspondenceAddress?.cidade,
    correspondenceAddress?.logradouro,
    correspondenceAddress?.numero,
    correspondenceAddress?.uf,
    residentialAddress?.bairro,
    residentialAddress?.cep,
    residentialAddress?.cidade,
    residentialAddress?.logradouro,
    residentialAddress?.numero,
    residentialAddress?.uf,
    setRequireAdress,
  ]);

  React.useEffect(() => {
    if (isCancel) {
      const address = handleDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const newDataAdress = handleNewDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const correspondence = handleDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Correspo")
      );
      const newCorrespondence = handleNewDataMainBuyer?.enderecos?.find(
        (address) => address?.tipoDeRegistro?.includes("Correspo")
      );

      const handleUseId = () => {
        if (
          !newDataAdress?.idEnderecoSalesforce &&
          !address?.idEnderecoSalesforce
        )
          return "";
        if (newDataAdress?.idEnderecoSalesforce)
          return newDataAdress?.idEnderecoSalesforce;
        return address?.idEnderecoSalesforce;
      };

      setResidentialAddress({
        idEnderecoSalesforce: handleUseId(),
        cep: newDataAdress?.cep ? newDataAdress?.cep : address?.cep || "",
        logradouro: newDataAdress?.logradouro
          ? newDataAdress?.logradouro
          : address?.logradouro || "",
        numero: newDataAdress?.numero
          ? newDataAdress?.numero
          : address?.numero || "",
        complemento: newDataAdress?.complemento
          ? newDataAdress?.complemento
          : address?.complemento || "",
        bairro: newDataAdress?.bairro
          ? newDataAdress?.bairro
          : address?.bairro || "",
        cidade: newDataAdress?.cidade
          ? newDataAdress?.cidade
          : address?.cidade || "",
        uf: newDataAdress?.uf ? newDataAdress?.uf : address?.uf || "",
      });

      const handleUseIdCorrespondence = () => {
        if (
          !correspondence?.idEnderecoSalesforce &&
          !newCorrespondence?.idEnderecoSalesforce
        )
          return "";
        if (newCorrespondence?.idEnderecoSalesforce)
          return newCorrespondence?.idEnderecoSalesforce;
        return correspondence?.idEnderecoSalesforce;
      };
      setCorrespondenceAddress({
        idEnderecoSalesforce: handleUseIdCorrespondence(),
        cep: newCorrespondence?.cep
          ? newCorrespondence?.cep
          : correspondence?.cep || "",
        logradouro: newCorrespondence?.logradouro
          ? newCorrespondence?.logradouro
          : correspondence?.logradouro || "",
        numero: newCorrespondence?.numero
          ? newCorrespondence?.numero
          : correspondence?.numero || "",
        complemento: newCorrespondence?.complemento
          ? newCorrespondence?.complemento
          : correspondence?.complemento || "",
        bairro: newCorrespondence?.bairro
          ? newCorrespondence?.bairro
          : correspondence?.bairro || "",
        cidade: newCorrespondence?.cidade
          ? newCorrespondence?.cidade
          : correspondence?.cidade || "",
        uf: newCorrespondence?.uf
          ? newCorrespondence?.uf
          : correspondence?.uf || "",
      });

      if (address?.cep !== correspondence?.cep && correspondence?.cep) {
        setCheckAdress(false);
      }
      setIsCancel(false);
    }
  }, [handleDataMainBuyer, isCancel]);

  React.useEffect(() => {
    if (handleDataMainBuyer || newRelationship) {
      const address = handleDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const newDataAdress = handleNewDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Resi")
      );
      const correspondence = handleDataMainBuyer?.enderecos?.find((address) =>
        address?.tipoDeRegistro?.includes("Corres")
      );
      const newCorrespondence = handleNewDataMainBuyer?.enderecos?.find(
        (address) => address?.tipoDeRegistro?.includes("Corres")
      );

      const handleUseId = () => {
        if (
          !newDataAdress?.idEnderecoSalesforce &&
          !address?.idEnderecoSalesforce
        )
          return "";
        if (newDataAdress?.idEnderecoSalesforce)
          return newDataAdress?.idEnderecoSalesforce;
        return address?.idEnderecoSalesforce;
      };

      setResidentialAddress({
        idEnderecoSalesforce: handleUseId(),
        cep: newDataAdress?.cep ? newDataAdress?.cep : address?.cep || "",
        logradouro: newDataAdress?.logradouro
          ? newDataAdress?.logradouro
          : address?.logradouro || "",
        numero: newDataAdress?.numero
          ? newDataAdress?.numero
          : address?.numero || "",
        complemento: newDataAdress?.complemento
          ? newDataAdress?.complemento
          : address?.complemento || "",
        bairro: newDataAdress?.bairro
          ? newDataAdress?.bairro
          : address?.bairro || "",
        cidade: newDataAdress?.cidade
          ? newDataAdress?.cidade
          : address?.cidade || "",
        uf: newDataAdress?.uf ? newDataAdress?.uf : address?.uf || "",
      });

      const handleUseIdCorrespondence = () => {
        if (
          !correspondence?.idEnderecoSalesforce &&
          !newCorrespondence?.idEnderecoSalesforce
        )
          return "";
        if (newCorrespondence?.idEnderecoSalesforce)
          return newCorrespondence?.idEnderecoSalesforce;
        return correspondence?.idEnderecoSalesforce;
      };

      setCorrespondenceAddress({
        idEnderecoSalesforce: handleUseIdCorrespondence(),
        cep: newCorrespondence?.cep
          ? newCorrespondence?.cep
          : correspondence?.cep || "",
        logradouro: newCorrespondence?.logradouro
          ? newCorrespondence?.logradouro
          : correspondence?.logradouro || "",
        numero: newCorrespondence?.numero
          ? newCorrespondence?.numero
          : correspondence?.numero || "",
        complemento: newCorrespondence?.complemento
          ? newCorrespondence?.complemento
          : correspondence?.complemento || "",
        bairro: newCorrespondence?.bairro
          ? newCorrespondence?.bairro
          : correspondence?.bairro || "",
        cidade: newCorrespondence?.cidade
          ? newCorrespondence?.cidade
          : correspondence?.cidade || "",
        uf: newCorrespondence?.uf
          ? newCorrespondence?.uf
          : correspondence?.uf || "",
      });

      if (address?.cep !== correspondence?.cep && correspondence?.cep) {
        setCheckAdress(false);
      }
    }
  }, [handleDataMainBuyer, handleNewDataMainBuyer, newRelationship]);

  React.useEffect(() => {
    if (isClicked) {
      handleUpdateAdress();
    }
  }, [isClicked]);

  return (
    <>
      {loadingCep ? (
        <Box
          display={"flex"}
          padding={"4px"}
          h={"100px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Styled.ContainerTitleAdress>
            <span>Residencial</span>
          </Styled.ContainerTitleAdress>

          <Styled.ContainerBoxInputs>
            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="CEP:"
              name="cep"
              value={residentialAddress?.cep}
              onChange={(e) => handleCepChange(e, setResidentialAddress)}
              maxLength={9}
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="Logradouro:"
              name="publicPlace"
              value={residentialAddress.logradouro}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  logradouro: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="Número:"
              name="number"
              value={residentialAddress.numero}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  numero: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              readOnly={!edit}
              name={"complement"}
              label="Complemento:"
              value={residentialAddress.complemento}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  complemento: e?.target?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="Bairro:"
              name="neighborhood"
              value={residentialAddress.bairro}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  bairro: e?.target?.value,
                }))
              }
            />

            <InputSelect
              isRequirement
              readOnly={!edit}
              title="Estado:"
              label={residentialAddress.uf || "Selecione uma opção"}
              isSelected={residentialAddress.uf}
              options={optionUF}
              selectedOption={residentialAddress.uf}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  uf: e?.currentTarget?.value,
                }))
              }
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="Cidade:"
              name="city"
              value={residentialAddress.cidade}
              onChange={(e) =>
                setResidentialAddress((prev) => ({
                  ...prev,
                  cidade: e?.target?.value,
                }))
              }
            />
          </Styled.ContainerBoxInputs>

          <Styled.ContainerCheckboxAdress>
            <Checkbox
              disabled={!edit}
              isChecked={checkAdress}
              onChange={(e) => setCheckAdress(e.target.checked)}
              size="md"
            >
              <Text fontSize={"14px"}>
                O endereço de correspondência é igual ao residencial
              </Text>
            </Checkbox>
          </Styled.ContainerCheckboxAdress>

          {!checkAdress && (
            <>
              <Styled.ContainerTitleAdress>
                <span>Correspondência</span>
              </Styled.ContainerTitleAdress>

              <Styled.ContainerBoxInputs>
                <InputTextEdit
                  isRequirement
                  readOnly={!edit}
                  label="CEP:"
                  name="cep"
                  value={correspondenceAddress?.cep}
                  onChange={(e) =>
                    handleCepComposerChange(e, setCorrespondenceAddress)
                  }
                  maxLength={9}
                />

                <InputTextEdit
                  isRequirement
                  readOnly={!edit}
                  label="Logradouro:"
                  name="publicPlace"
                  value={correspondenceAddress.logradouro}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      logradouro: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement
                  readOnly={!edit}
                  label="Número:"
                  name="number"
                  value={correspondenceAddress.numero}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      numero: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  readOnly={!edit}
                  name={"complement"}
                  label="Complemento:"
                  value={correspondenceAddress.complemento}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      complemento: e?.target?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement
                  readOnly={!edit}
                  label="Bairro:"
                  name="neighborhood"
                  value={correspondenceAddress.bairro}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      bairro: e?.target?.value,
                    }))
                  }
                />

                <InputSelect
                  isRequirement
                  readOnly={!edit}
                  title="Estado:"
                  label={correspondenceAddress.uf || "Selecione uma opção"}
                  isSelected={correspondenceAddress.uf}
                  options={optionUF}
                  selectedOption={correspondenceAddress.uf}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      uf: e?.currentTarget?.value,
                    }))
                  }
                />

                <InputTextEdit
                  isRequirement
                  readOnly={!edit}
                  label="Cidade:"
                  name="city"
                  value={correspondenceAddress.cidade}
                  onChange={(e) =>
                    setCorrespondenceAddress((prev) => ({
                      ...prev,
                      cidade: e?.target?.value,
                    }))
                  }
                />
              </Styled.ContainerBoxInputs>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdressClientComponent;
