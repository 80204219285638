import React from "react";
import * as Styled from "../stylesStepTab";
import Button from "@components/common/Button";
import OportunityService from "@services/Oportunity/OportunityService";
import { GiSuitcase } from "react-icons/gi";
import { MdOutlineCall, MdOutlineEditNote } from "react-icons/md";
import Spinner from "@components/common/Spinner";
import { FiPlus } from "react-icons/fi";
import { theme } from "src/styles/theme";
import { OportunityContext } from "@context/oportunityContex";
import Modal from "@components/Sales/Modal";
import ViewTasksCallComponent from "./ViewTasksCallComponent";
import { ITasksCallsComponent } from "src/@types/Sales";

const TasksCallsComponent = ({
  index,
  indexFirstTab,
  navigateCreateTask,
  successCreateTaskCall,
  updateData,
  showDetailTaskCall,
  setShowDetailTaskCall,
}: ITasksCallsComponent) => {
  const {
    faseOp,
    accessTaskCalls,
    setAccessTasksCalls,
    accessCreateOp,
    successCreateTask,
    setSuccessCreateTask,
  } = React.useContext(OportunityContext);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [edit, setEdit] = React.useState(false);
  const [successEditTaskCall, setSuccessEditTaskCall] = React.useState(false);

  const filteredRows = React.useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data;
  }, [data]);

  function handleType(type: string) {
    if (!type) return "";
    if (type?.toLowerCase()?.includes("task")) return "Tarefa";
    if (type?.toLowerCase()?.includes("call")) return "Chamada";
    return "";
  }

  function handleTypeIcon(type: string) {
    if (handleType(type) === "Tarefa") return <GiSuitcase color="white" />;
    if (handleType(type) === "Chamada") return <MdOutlineCall color="white" />;
    return "";
  }

  const handleGetTasks = async () => {
    setLoading(true);
    try {
      const response = await OportunityService.getTasks({
        OpportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
      });

      if (response.status === 401) {
        setLoading(false);
        return;
      }

      setData(Array.isArray(response) ? response : []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleGetTasks", error);
    }
  };

  function handleDate(date: string) {
    if (!date) return "";
    const [a] = date?.split(" ");

    return a;
  }

  React.useEffect(() => {
    if (index === 0 && indexFirstTab === 2) {
      handleGetTasks();
    }
    if (successCreateTaskCall || successEditTaskCall) {
      handleGetTasks();
    }
  }, [
    accessCreateOp,
    successEditTaskCall,
    index,
    indexFirstTab,
    successCreateTaskCall,
    updateData,
  ]);

  return (
    <>
      <Modal
        isModalDetail
        onOpen={showDetailTaskCall || successCreateTask}
        title={handleType(accessTaskCalls.tipoDeRegistro)}
        onClose={() => {
          setShowDetailTaskCall(false);
          setSuccessCreateTask(false);
        }}
        colorBgIcon={
          accessTaskCalls.tipoDeRegistro?.toLowerCase().includes("task")
            ? theme.info
            : theme.available50
        }
        iconTitle={handleTypeIcon(accessTaskCalls.tipoDeRegistro)}
      >
        <ViewTasksCallComponent
          onBack={() => setShowDetailTaskCall(false)}
          idTarefaSalesforce={accessTaskCalls.idTarefaSalesforce}
          isTaskOrCall={handleType(accessTaskCalls.tipoDeRegistro)}
          isEdit={edit}
          setIsEdit={setEdit}
          setShowDetailTaskCall={setShowDetailTaskCall}
          setSuccessEditTaskCall={setSuccessEditTaskCall}
        />
      </Modal>

      <Styled.ContainerHistoricSchedule>
        <Styled.ContainerHeader>
          <Styled.ContaineButtonsHeader>
            <Button
              title={
                <Styled.TitleButon>
                  <FiPlus />
                  NOVA ATIVIDADE
                </Styled.TitleButon>
              }
              onClick={navigateCreateTask}
              disabled={faseOp}
            />
          </Styled.ContaineButtonsHeader>
        </Styled.ContainerHeader>

        {loading ? (
          <Styled.ContainerTodoHistoricLoading>
            <Spinner />
          </Styled.ContainerTodoHistoricLoading>
        ) : (
          <>
            <Styled.ContainerTitleLabelHistoric>
              {filteredRows?.length === 0 ? (
                <Styled.ContainerTodoHistoricEmpty>
                  <span>Não há tarefa ou chamada nesta oportunidade.</span>
                </Styled.ContainerTodoHistoricEmpty>
              ) : (
                <Styled.TitleLabelHistoricLeft>
                  Tipo:
                </Styled.TitleLabelHistoricLeft>
              )}
            </Styled.ContainerTitleLabelHistoric>

            <Styled.ContainerTodoHistoric>
              {filteredRows?.map(
                ({
                  idTarefaSalesforce,
                  tipoDeRegistro,
                  assunto,
                  dataDeVencimento,
                  dataCriacao,
                }) => (
                  <Styled.ContainerSchedule key={idTarefaSalesforce}>
                    <Styled.ContainerIconTextHistoric>
                      <Styled.ContainerIconTasksCalls type={tipoDeRegistro}>
                        {handleTypeIcon(tipoDeRegistro)}
                      </Styled.ContainerIconTasksCalls>
                      <Styled.ContainerTextHistoric>
                        <Styled.TitleTextHistoric>
                          {handleType(tipoDeRegistro)}
                        </Styled.TitleTextHistoric>
                        {handleType(tipoDeRegistro) === "Tarefa" && (
                          <Styled.SubTitleTextHistoric>
                            Assunto: {assunto}
                          </Styled.SubTitleTextHistoric>
                        )}
                      </Styled.ContainerTextHistoric>
                    </Styled.ContainerIconTextHistoric>

                    <Styled.ContainerDataTasksCalls>
                      <Styled.ContainerIconTextDataTasksCalls>
                        <Styled.ContainerTexDataStatus>
                          <Styled.TitleTexDataStatus>
                            {handleType(tipoDeRegistro) === "Tarefa"
                              ? "Data de vencimento"
                              : "Data da criação"}
                          </Styled.TitleTexDataStatus>
                          <Styled.SubTitleTextDataStatus>
                            {handleType(tipoDeRegistro) === "Tarefa"
                              ? `${dataDeVencimento}`
                              : `${handleDate(dataCriacao)}`}
                          </Styled.SubTitleTextDataStatus>
                        </Styled.ContainerTexDataStatus>
                      </Styled.ContainerIconTextDataTasksCalls>

                      <Styled.BoxDivider>
                        <Styled.DividerActivity />
                      </Styled.BoxDivider>

                      <Styled.ContainerButtonDataStatus>
                        <Styled.ContainerButtonIconDataTasksCalls>
                          <Styled.AreaButtonIconDataTasksCalls
                            faseOp={faseOp}
                            onClick={() => {
                              if (faseOp) {
                                return;
                              } else {
                                setEdit(true);
                                setAccessTasksCalls({
                                  idTarefaSalesforce,
                                  tipoDeRegistro,
                                });
                                setShowDetailTaskCall(true);
                              }
                            }}
                          >
                            <span>Editar</span>
                            <MdOutlineEditNote />
                          </Styled.AreaButtonIconDataTasksCalls>
                        </Styled.ContainerButtonIconDataTasksCalls>
                        <Button
                          title="VISUALIZAR"
                          onClick={() => {
                            setAccessTasksCalls({
                              idTarefaSalesforce,
                              tipoDeRegistro,
                            });
                            setShowDetailTaskCall(true);
                          }}
                        />
                      </Styled.ContainerButtonDataStatus>
                    </Styled.ContainerDataTasksCalls>
                  </Styled.ContainerSchedule>
                )
              )}
            </Styled.ContainerTodoHistoric>
          </>
        )}
      </Styled.ContainerHistoricSchedule>
    </>
  );
};

export default TasksCallsComponent;
