import React from "react";
import * as Styled from "./styles";
import { theme } from "src/styles/theme";
import { Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";

interface IInputCPF {
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  iconClick?: React.MouseEventHandler<SVGElement>;
  error?: any;
  message?: any;
  isLabel?: boolean;
}

const InputCPF = ({
  value,
  onChange,
  placeholder,
  error,
  message,
  iconClick,
  isLabel,
}: IInputCPF) => {
  return (
    <Styled.Area>
      {isLabel && (
        <Text fontSize={"14px"} color={theme.text} mb={"8px"}>
          CPF/CNPJ
        </Text>
      )}
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={error}
          maxLength={18}
          focusBorderColor={theme.primary}
          borderWidth="2px"
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.text }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
        <InputRightElement>
          {value && <Styled.IconAiOutlineCloseCircle onClick={iconClick} />}
        </InputRightElement>
      </InputGroup>

      {message}
    </Styled.Area>
  );
};

export default InputCPF;
