import React from "react";
import { hideEmail } from "@helpers/emailCheck";
import Spinner from "@components/common/Spinner";
import Helper from "@components/Login/Helper";
import {
  Box,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { IoIosArrowRoundBack } from "react-icons/io";

interface ITwoFactorAuthnticationComponent {
  loading: boolean;
  loadingResend: boolean;
  email: string;
  code: string;
  setCode: any;
  error: any;
  setError: any;
  handleResendCode: () => void;
  onBack: () => void;
}

const TwoFactorAuthnticationComponent = ({
  loading,
  loadingResend,
  email,
  code,
  setCode,
  error,
  setError,
  handleResendCode,
  onBack,
}: ITwoFactorAuthnticationComponent) => {
  return (
    <>
      <Flex
        flexDir={"column"}
        w={{ base: "80%", md: "55%", lg: "55%", xl: "80%" }}
        h={"85%"}
        align={"center"}
        justify={"center"}
      >
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={"20px"}
          textAlign={"center"}
        >
          <Flex justifyContent={"center"} alignItems={"center"} gap={"8px"}>
            <Box w={"fit-content"} cursor={"pointer"} onClick={onBack}>
              <IoIosArrowRoundBack
                fontSize="28px"
                color={theme.primary}
                width={"fit-content"}
              />
            </Box>
            <Text
              fontSize={"18px"}
              w={"fit-content"}
              color={theme.text}
              fontWeight={"medium"}
            >
              Verifique seu e-mail
            </Text>
          </Flex>

          <Text fontSize={"13px"} color={theme.text} mt={"18px"}>
            Enviamos um código de verificação para o e-mail:
          </Text>

          <Flex mt={"5px"}>
            <Text fontSize={"13px"} color={theme.text} fontWeight={"bold"}>
              {hideEmail(email)}
            </Text>
          </Flex>
        </Flex>

        <Flex
          flexDir={"column"}
          mt={"15px"}
          mb={"20px"}
          justify={"center"}
          align={"center"}
        >
          {loading ? (
            <Flex h={"78px"} justify={"center"} align={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <HStack flexDir={"column"} w={"fit-content"}>
              <Text fontSize={"14px"} color={theme.text} mb={"8px"}>
                Código de verificação
              </Text>

              <HStack align={"center"} w={"fit-content"}>
                <PinInput
                  autoFocus
                  size={"lg"}
                  focusBorderColor={theme.primary}
                  value={code}
                  onChange={(e) => {
                    setCode(e);
                    setError((prevState: any) => {
                      return {
                        ...prevState,
                        errorConfirmCode: false,
                      };
                    });
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </HStack>
          )}
        </Flex>

        {error.errorConfirmCode ? (
          <Flex
            flexDir={"column"}
            justify={"center"}
            w={"90%"}
            h={"30%"}
            mb={"20px"}
          >
            <Box
              display={"flex"}
              border={"2px"}
              borderColor={theme.error}
              borderRadius={"8px"}
              justifyContent={"center"}
              alignItems={"center"}
              p={"8px"}
            >
              <Text
                fontSize={"14px"}
                fontWeight={"medium"}
                color={theme.error}
                w={"fit-content"}
              >
                Código inválido
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex h={"30%"} mb={"20px"} />
        )}
      </Flex>

      <Helper
        isResendCode
        loadingResend={loadingResend}
        onClickResend={handleResendCode}
      />
    </>
  );
};

export default TwoFactorAuthnticationComponent;
