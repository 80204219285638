import React from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "@context/loginContext";
import Logo from "../../assets/logo_small.svg";
import Helper from "@components/Login/Helper";
import TabsSolo from "@components/Login/TabsSolo";
import InputSelect from "@components/Login/Inputs/InputSelect";
import InputTextArea from "@components/Login/Inputs/InputTextArea";
import Button from "@components/common/Button";
import NewInputEmail from "@components/Login/Inputs/NewInputEmail";
import { optionRegional, optionTema } from "@helpers/optionSelect";
import { isEmail } from "@helpers/emailCheck";
import * as Styled from "./styles";
import needHelpService from "@services/NeedHelp/needHelpService";
import { formatIncorporation, formatRegional } from "@helpers/format";
import Spinner from "@components/common/Spinner";
import { IHashMapKeyString } from "src/@types/NeedHelp";

const NeedHelp = () => {
  const navigate = useNavigate();
  const { pageHelper } = React.useContext(LoginContext);
  const [hash, setHash] = React.useState<string>("NeedHelp");
  const [selectOption, setSelectOption] = React.useState<string>("");
  const [selectOptionTema, setSelectOptionTema] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [incorporation, setIncorporation] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [validForm, setValidForm] = React.useState({
    errorRegional: false,
    errorTema: false,
    errorEmail: false,
    errorName: false,
    errorMessage: false,
  });

  const isRadioSelected = (value: string): boolean => selectOption === value;
  const isRadioSelectedTema = (value: string): boolean =>
    selectOptionTema === value;

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectOption(e.currentTarget.value);
    setIncorporation(e.currentTarget.value);
    setError(false);
  };
  const handleRadioTemaClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectOptionTema(e.currentTarget.value);
    setError(false);
  };

  const handleValidForm = () => {
    const validRegional = selectOption;
    const validTema = selectOptionTema;
    const validEmail = isEmail(email) && email;
    const validMessage = message;
    const validName =
      name
        ?.trim()
        ?.split(" ")
        ?.filter((n) => n).length >= 2;

    setValidForm({
      errorRegional: !validRegional,
      errorTema: !validTema,
      errorEmail: !validEmail,
      errorName: !validName,
      errorMessage: !validMessage,
    });

    if (validRegional && validTema && validEmail && validName && validMessage) {
      handleSendHelp();
    }
  };

  const handleRegional = () => {
    if (
      selectOption === "SP Capital Riva" ||
      selectOption === "SP Capital Direcional"
    )
      return "SPC";
    else if (
      selectOption === "SP Interior Riva" ||
      selectOption === "SP Interior Direcional"
    )
      return "SPI";
    else return formatRegional(selectOption);
  };
  const handleIncorporiation = () => {
    if (
      selectOption === "SP Capital Riva" ||
      selectOption === "SP Interior Riva"
    )
      return "Riva";
    else if (
      selectOption === "SP Capital Direcional" ||
      selectOption === "SP Interior Direcional"
    )
      return "Direcional";
    else return formatIncorporation(incorporation);
  };

  const handleSendHelp = async () => {
    setLoading(true);

    try {
      const response = await needHelpService.postNeedHelp({
        nome: name,
        regional: handleRegional(),
        incorporadora: handleIncorporiation(),
        email,
        motivos: selectOptionTema,
        mensagem: message,
      });

      if (response?.status >= 400) {
        console.log("errorStutusNeedHelp", response);
        setLoading(false);
        setError(true);
        return;
      }

      setLoading(false);
      setError(false);
      setHash("SuccessAccess");
    } catch (error) {
      setLoading(false);
      console.log("handleSendHelp", error);
    }
  };

  const renderRegionalOptions = () => {
    return optionRegional
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name }) => {
        return (
          <Styled.Items key={id}>
            <Styled.ContainerRadioButton>
              <Styled.LabelRadioButton id={id}>
                <Styled.Input
                  type="radio"
                  name={name}
                  id={id}
                  value={name}
                  checked={isRadioSelected(name)}
                  onChange={handleRadioClick}
                />
                <Styled.RadioBox></Styled.RadioBox>
                <Styled.Paragraph>{name}</Styled.Paragraph>
              </Styled.LabelRadioButton>
            </Styled.ContainerRadioButton>
          </Styled.Items>
        );
      });
  };

  const renderTemaOptions = () => {
    return optionTema.map(({ id, name }) => {
      return (
        <Styled.Items key={id}>
          <Styled.ContainerRadioButton>
            <Styled.LabelRadioButton id={id}>
              <Styled.Input
                type="radio"
                name={name}
                id={id}
                value={name}
                checked={isRadioSelectedTema(name)}
                onChange={handleRadioTemaClick}
              />
              <Styled.RadioBox></Styled.RadioBox>
              <Styled.Paragraph>{name}</Styled.Paragraph>
            </Styled.LabelRadioButton>
          </Styled.ContainerRadioButton>
        </Styled.Items>
      );
    });
  };

  const NeedHelpHashMap: IHashMapKeyString = {
    NeedHelp: (
      <>
        <Styled.AreaNeedHelp>
          <Styled.Form>
            <Styled.ContainerRegional>
              <Styled.SubContainerRegional>
                <InputSelect
                  title="Regional:"
                  label={selectOption || "Escolha sua regional"}
                  isSelected={selectOption}
                  error={validForm.errorRegional}
                  messageError="Selecione a regional mais próxima de você"
                  isHeight
                >
                  {renderRegionalOptions()}
                </InputSelect>
              </Styled.SubContainerRegional>
            </Styled.ContainerRegional>

            <Styled.BoxInput>
              <Styled.AreaInput>
                <NewInputEmail
                  isName
                  placeholder="Nome"
                  isLabel="Informe seu nome:"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError(false);
                  }}
                  iconClick={() => {
                    setName("");
                    setError(false);
                  }}
                  error={validForm.errorName}
                  message="Formato inválido"
                />
                <NewInputEmail
                  placeholder="E-mail"
                  isLabel="Informe seu e-mail:"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError(false);
                  }}
                  iconClick={() => {
                    setEmail("");
                    setError(false);
                  }}
                  error={validForm.errorEmail}
                  message="Formato inválido"
                />

                <InputSelect
                  title="Tema:"
                  label={selectOptionTema || "Selecione um tema"}
                  isSelected={selectOptionTema}
                  error={validForm.errorTema}
                  messageError="Selecione um tema adequado a sua dúvida"
                >
                  {renderTemaOptions()}
                </InputSelect>

                <InputTextArea
                  label="Mensagem:"
                  placeholder="Digite sua mensagem aqui."
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setError(false);
                  }}
                  error={validForm.errorMessage}
                  messageError="Digite uma mensagem que descreva sua dúvida."
                />
              </Styled.AreaInput>
            </Styled.BoxInput>
          </Styled.Form>
        </Styled.AreaNeedHelp>

        <Helper isModify>
          <Styled.ContainerButtonHelper>
            {!error ? (
              <>
                <Button
                  error
                  title={"Voltar"}
                  onClick={() => {
                    navigate("/login", {
                      state: { message: pageHelper },
                    });
                  }}
                />

                <Button
                  title={loading ? <Spinner /> : "Enviar"}
                  onClick={handleValidForm}
                  disabled={loading}
                  colorDisabled={
                    !selectOption ||
                    !email ||
                    !name ||
                    !selectOptionTema ||
                    !message
                  }
                />
              </>
            ) : (
              <Button error title="ERROR AO ENVIAR O FORMULÁRIO" />
            )}
          </Styled.ContainerButtonHelper>
        </Helper>
      </>
    ),
    SuccessAccess: (
      <>
        <Styled.AreaNeedHelp>
          <Styled.ContainerSuccessAccessTitle>
            <span>Agradecemos pela mensagem!</span>
          </Styled.ContainerSuccessAccessTitle>
          <Styled.ContainerSuccessAccessBody>
            <span>
              <strong>
                Sua solicitação foi enviada para o time de atendimento.
              </strong>{" "}
              Em breve, entraremos em contato.
            </span>
          </Styled.ContainerSuccessAccessBody>
        </Styled.AreaNeedHelp>

        <Helper>
          <Styled.ContainerButtonHelper>
            <Button
              title="Acessar o portal"
              onClick={() => {
                navigate("/login", {
                  state: { message: pageHelper },
                });
              }}
            />
          </Styled.ContainerButtonHelper>
        </Helper>
      </>
    ),
  };

  return (
    <Styled.Area>
      <Styled.Container>
        <Styled.ContainerLeft>
          <img
            alt="background"
            src={require("../../assets/background-needhelp.png")}
          />
        </Styled.ContainerLeft>

        <Styled.ContainerRight>
          <Styled.AreaRight>
            <Styled.ContainerRightTab>
              <Styled.ContainerTabs>
                <TabsSolo
                  isActive="Preciso de ajuda"
                  page="Preciso de ajuda"
                  subtitle={
                    hash === "NeedHelp"
                      ? "Preencha o formulário abaixo."
                      : "Solicitação preenchida"
                  }
                  onBack={() => {
                    if (hash === "SuccessAccess") {
                      setHash("NeedHelp");
                      setEmail("");
                      setSelectOption("");
                      setSelectOptionTema("");
                      setMessage("");
                    } else {
                      navigate("/login", {
                        state: { message: pageHelper },
                      });
                    }
                  }}
                />
              </Styled.ContainerTabs>

              <img src={Logo} alt="Logo" />
            </Styled.ContainerRightTab>

            <Styled.BodyRight>{NeedHelpHashMap[hash]}</Styled.BodyRight>
          </Styled.AreaRight>
        </Styled.ContainerRight>
      </Styled.Container>
    </Styled.Area>
  );
};

export default NeedHelp;
