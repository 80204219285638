import {
  Tabs,
  TabList,
  Tab as CkTab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { useScrollableBoxProps } from "@hook/useEqualCode";

interface ITab {
  width: string;
  widthXl?: string;
  widthMd?: string;
  widthSm?: string;
  widthBase?: string;
  fontSize?: string;
  tabLabels: string[];
  tabContents: Record<string, React.ReactNode>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  index?: number; // Novo: índice inicial como prop
  disabled?: boolean;
}

const Tab = ({
  width,
  widthXl,
  widthBase,
  widthSm,
  widthMd,
  fontSize,
  tabLabels,
  tabContents,
  setIndex,
  index, // Recebe o índice inicial
  disabled = false,
}: ITab) => {
  const scrollProps = useScrollableBoxProps();

  return (
    <Tabs
      index={index} // Controla o índice inicial
      onChange={(index) => setIndex(index)}
    >
      <TabList {...scrollProps} w={"100%"}>
        {tabLabels.map((label) => (
          <CkTab
            w={"fit-content"}
            fontSize={fontSize}
            key={label}
            color={theme.text}
            _selected={{ color: theme.primary, fontWeight: "bold" }}
            isDisabled={disabled}
          >
            {label}
          </CkTab>
        ))}
      </TabList>

      <TabPanels>
        {tabLabels.map((label) => (
          <TabPanel key={label}>{tabContents[label]}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default Tab;
