import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "src/styles/theme";
import * as Styled from "../Steps/stylesStepTab";
import InputText from "@components/Sales/Inputs/InputText";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import {
  optionAgeRange,
  optionCivilStatus,
  optionMarriageRegime,
  optionNationality,
  optionOriginAccount,
  optionSex,
  optionTypeRelationship,
} from "@helpers/optionSelect";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import {
  documentMask,
  formatDatePtBr,
  phoneMask,
  rgMask,
} from "@helpers/format";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import { IAccordionBuyersComponent } from "src/@types/Sales";
import { optionProfession } from "./options";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import AttachmentsComponent from "../Steps/Attachments/AttachmentsComponent";
import { OportunityContext } from "@context/oportunityContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import AdressClientComponent from "./SectionAdressUpdateDataClient/AdressClientComponent";
import { useOnlyMessageToast } from "@hook/useMessageToast";

const AccordionBuyersComponent = ({
  formik,
  accountIdSalesforce,
  type,
  edit,
  setEdit,
  originAccount,
  setOriginAccount,
  nacionality,
  setNacionality,
  ageRange,
  setAgeRange,
  sex,
  setSex,
  profession,
  setProfession,
  civilState,
  setCivilState,
  marriageRegime,
  setMarriageRegime,
  loadingProfession,
  selectProfession,
  setSelectProfession,
  dataProfession,
  getProfession,
  setIdProfession,
  relationship,
  setLoadingUpdateAdress,
  observationsGa,
  observationsSDR,
  cancelButtonAdress,
  setCancelButtonAdress,
  codigoRelacionamentoComComprador,
  existCpf,
  disabledCreateDocument,
  idRelacionamentoComprador,
  handleCancelButtonAccordion,
  loadingUpdate,
  require,
  account,
  typeRelationship,
  setTypeRelationship,
  handleSaveRelationship,
  cpf,
  setCpf,
  indexRelationship,
  getOp
}: IAccordionBuyersComponent) => {
  const displayMessage = useOnlyMessageToast();
  const { onboardingDocuments, accessRelationship, requireAdress } =
    React.useContext(OportunityContext);
  let attachmentsTitle = "Anexos";
  if (onboardingDocuments.length > 0) {
    attachmentsTitle += ` (${onboardingDocuments.length})`;
  }
  const [isUpdateAdress, setIsUpdateAdress] = React.useState(false);
  const currentBuyer = relationship[indexRelationship];

  // const handleRequireOriginAccount = (originAccount: string) => {
  //   return (
  //     originAccount !== "Oferta do corretor" &&
  //     originAccount !== "Leads autorais"
  //   );
  // };

  const renderedOptionsProfession = optionProfession(
    dataProfession,
    selectProfession,
    setSelectProfession,
    setProfession,
    setIdProfession
  );

  return (
    <Accordion allowMultiple>
      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Dados pessoais
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Flex>
            <Text fontSize={"18px"} fontWeight={"semibold"}>
              Geral
            </Text>
          </Flex>

          {type === "Comprador Principal" ? (
            <>
              <Styled.ContainerBoxInputs>
                <InputText
                  tooltip
                  tooltipText="É possível editar o nome da conta ao enviar avaliação de crédito para análise."
                  isRequirement
                  readOnly
                  label="Nome da conta:"
                  name="nameAccount"
                  value={formik.values.nameAccount?.toUpperCase()}
                />

                <InputSelect
                  isRequirement
                  readOnly
                  title="Origem da conta:"
                  label={originAccount || "Selecione uma opção"}
                  isSelected={originAccount}
                  options={optionOriginAccount}
                  selectedOption={originAccount}
                  onChange={(e) => setOriginAccount(e.currentTarget.value)}
                />

                <InputTextEdit
                  name={"dateBirth"}
                  label="Data de nascimento:"
                  placeholder="__/__/___"
                  value={formik.values.dateBirth}
                  onChange={(e) => {
                    const newValue = formatDatePtBr(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("dateBirth", newValue);
                  }}
                  maxLength={10}
                />

                <InputSelect
                  title="Nacionalidade:"
                  label={nacionality || "Selecione uma opção"}
                  isSelected={nacionality}
                  options={optionNationality}
                  selectedOption={nacionality}
                  onChange={(e) => setNacionality(e.currentTarget.value)}
                />

                <InputSelect
                  title="Faixa Etária:"
                  label={ageRange || "Selecione uma opção"}
                  isSelected={ageRange}
                  options={optionAgeRange}
                  selectedOption={ageRange}
                  onChange={(e) => setAgeRange(e.currentTarget.value)}
                />

                <InputSelect
                  title="Sexo:"
                  label={sex || "Selecione uma opção"}
                  isSelected={sex}
                  options={optionSex}
                  selectedOption={sex}
                  onChange={(e) => setSex(e.currentTarget.value)}
                />

                <InputSelectSearch
                  width="22%"
                  height="15%"
                  title="Profissão:"
                  placeholder="Pesquise por profissão"
                  value={selectProfession}
                  onChangeInput={(e) => setSelectProfession(e.target.value)}
                  isSelected={profession}
                  onChangeIcon={() => {
                    setSelectProfession("");
                    setProfession("");
                  }}
                  onClickSearchIcon={getProfession}
                  loading={loadingProfession}
                >
                  {renderedOptionsProfession}
                </InputSelectSearch>

                <InputSelect
                  title="Estado civil:"
                  label={civilState || "Selecione uma opção"}
                  isSelected={civilState}
                  options={optionCivilStatus}
                  selectedOption={civilState}
                  onChange={(e) => setCivilState(e.currentTarget.value)}
                />

                {civilState === "Casado(a)" && (
                  <>
                    <InputTextEdit
                      name={"weddingDate"}
                      label="Data casamento:"
                      placeholder="__/__/___"
                      value={formik.values.weddingDate}
                      onChange={(e) => {
                        const newValue = formatDatePtBr(e.target.value);
                        formik.handleChange(e);
                        formik.setFieldValue("weddingDate", newValue);
                      }}
                      maxLength={10}
                      isRequirement
                    />

                    <InputSelect
                      title="Regime casamento:"
                      label={marriageRegime || "Selecione uma opção"}
                      isSelected={marriageRegime}
                      options={optionMarriageRegime}
                      selectedOption={marriageRegime}
                      onChange={(e) => setMarriageRegime(e.currentTarget.value)}
                      isRequirement
                    />
                  </>
                )}
              </Styled.ContainerBoxInputs>

              <Flex mt={"16px"}>
                <Text fontSize={"18px"} fontWeight={"semibold"}>
                  Documentos
                </Text>
              </Flex>
              <Styled.ContainerBoxInputs>
                <InputTextEdit
                  label="CPF/CNPJ:"
                  name="cpf"
                  value={formik.values.cpf}
                  onChange={(e) => {
                    const newValue = documentMask(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("cpf", newValue);
                  }}
                  maxLength={18}
                />

                <InputTextEdit
                  label="RG:"
                  name="rg"
                  value={formik.values.rg}
                  onChange={(e) => {
                    const newValue = rgMask(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("rg", newValue);
                  }}
                  maxLength={18}
                />

                <InputTextEdit
                  name={"shippingDate"}
                  label="Data de expedição:"
                  placeholder="__/__/___"
                  value={formik.values.shippingDate}
                  onChange={(e) => {
                    const newValue = formatDatePtBr(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("shippingDate", newValue);
                  }}
                  maxLength={10}
                />

                <InputTextEdit
                  label="Órgão expedidor:"
                  name="issuingBody"
                  value={formik.values.issuingBody}
                  onChange={formik.handleChange}
                />

                <InputText
                  readOnly
                  isRequirement
                  label="Telefone principal:"
                  name="mainTelephone"
                  value={formik.values.mainTelephone}
                />
              </Styled.ContainerBoxInputs>

              <Flex mt={"16px"}>
                <Text fontSize={"18px"} fontWeight={"semibold"}>
                  Informações de contato
                </Text>
              </Flex>
              <Styled.ContainerBoxInputs>
                <InputTextEdit
                  label="Telefone:"
                  name="telephone"
                  value={formik.values.telephone}
                  onChange={(e) => {
                    const newValue = phoneMask(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("telephone", newValue);
                  }}
                  maxLength={14}
                />

                <InputTextEdit
                  isRequirement
                  label="E-mail principal:"
                  name="mainEmail"
                  value={formik.values.mainEmail}
                  onChange={formik.handleChange}
                />

                <InputTextEdit
                  label="E-mail:"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Styled.ContainerBoxInputs>

              <Flex mt={"16px"}>
                <Text fontSize={"18px"} fontWeight={"semibold"}>
                  Informações GA/SDR
                </Text>
              </Flex>

              <Styled.ContainerInputObservation>
                <InputTextArea
                  readOnly
                  label="Observações GA:"
                  name="observationsGA"
                  value={observationsGa}
                  noMaxLength
                />
              </Styled.ContainerInputObservation>

              <Styled.ContainerInputObservation>
                <InputTextArea
                  readOnly
                  label="Observações SDR:"
                  name="observationsSDR"
                  value={observationsSDR}
                  noMaxLength
                />
              </Styled.ContainerInputObservation>
            </>
          ) : (
            <Styled.ContainerBoxInputs>
              <InputText
                tooltip
                tooltipText="É possível editar o nome da conta ao enviar avaliação de crédito para análise."
                readOnly
                label="Conta:"
                name="Conta"
                value={account || accessRelationship.nomeConta}
              />

              <InputTextEdit
                label="CPF/CNPJ:"
                name="cpf"
                value={cpf}
                onChange={(e) => {
                  setCpf(documentMask(e.target.value));
                }}
                maxLength={18}
              />

              <InputSelect
                isRequirement
                widht="23%"
                title="Tipo de relacionamento:"
                label={typeRelationship || "Selecione uma opção"}
                isSelected={typeRelationship}
                options={optionTypeRelationship}
                selectedOption={typeRelationship}
                onChange={(e) => setTypeRelationship(e.currentTarget.value)}
              />
            </Styled.ContainerBoxInputs>
          )}

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={() => {
                  handleCancelButtonAccordion();
                }}
              />

              <Button
                title={loadingUpdate ? <Spinner /> : "SALVAR"}
                onClick={() => {
                  if (type === "Comprador Principal") {
                    formik.handleSubmit();
                  } else {
                    handleSaveRelationship();
                  }
                }}
                disabled={
                  loadingUpdate || require
                  // || (edit && require)
                  // || disabledCpfCnpj
                }
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Endereço
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerActivitySubTab>
            <Flex flexDir={"column"}>
              <AdressClientComponent
                edit={true}
                accountIdSalesforce={accountIdSalesforce}
                relationship={currentBuyer}
                setLoadingUpdateAdress={setLoadingUpdateAdress}
                isCancel={cancelButtonAdress}
                setIsCancel={setCancelButtonAdress}
                isClicked={isUpdateAdress}
                setIsClicked={setIsUpdateAdress}
                onSuccess={() => {
                  displayMessage("Alterações salvas com sucesso!");
                  getOp();
                }}
              />
            </Flex>
          </Styled.ContainerActivitySubTab>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooterEdit>
              <Button
                error
                title="CANCELAR"
                onClick={() => {
                  handleCancelButtonAccordion();
                  // setCancelButtonAdress(true);
                }}
              />

              <Button
                title={loadingUpdate ? <Spinner /> : "SALVAR"}
                onClick={() => {
                  setIsUpdateAdress(true);
                }}
                disabled={
                  loadingUpdate || requireAdress
                  // || (edit && require)
                  // || disabledCpfCnpj
                }
              />
            </Styled.ContainerButtonFooterEdit>
          </Styled.ContainerFooterBox>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8} h={"50px"}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
          >
            {attachmentsTitle}{" "}
            {(existCpf === null || existCpf === "") && (
              <Text color={theme.error} fontSize={"12px"} w={"fit-content"}>
                (Informe o CPF/CNPJ para ativar o botão de "Novo Documento")
              </Text>
            )}
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <AttachmentsComponent
            idRelacionamentoComprador={idRelacionamentoComprador}
            codigoRelacionamentoComComprador={""}
            cpf={existCpf}
            disabledCreateDocument={disabledCreateDocument}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionBuyersComponent;
