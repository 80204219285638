import React from "react";
import * as Styled from "../styles";
import Box from "@components/Login/Box";
import InputCPF from "@components/Login/Inputs/InputCPF";
import { documentMask } from "@helpers/format";
import Button from "@components/common/Button";
import Helper from "@components/Login/Helper";
import Spinner from "@components/common/Spinner";
import { messageBox } from "./messageBox";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IForgotPasswordComponent {
  error: any;
  setError: any;
  document: string;
  setDocument: any;
  loading: boolean;
  setDigits: any;
  setPage: any;
  setErrorForgotPassword: any;
  handleEnter: () => void;
  onBack: () => void;
}

const ForgotPasswordComponent = ({
  error,
  setError,
  document,
  setDocument,
  setDigits,
  setPage,
  setErrorForgotPassword,
  loading,
  handleEnter,
  onBack,
}: IForgotPasswordComponent) => {
  const messageButtonText = error.errorDocument
    ? "Acesso Inválido"
    : "Continuar";

  const buttonText = loading ? <Spinner /> : messageButtonText;

  const handleCleanError = () => {
    setError((prevState: any) => {
      return {
        ...prevState,
        errorMessage: "",
        errorDocument: false,
      };
    });
  };

  const isCnpjComplete = documentMask(document)?.length === 18;
  const validDocument =
    documentMask(document)?.length < 14 ||
    (documentMask(document)?.length > 14 && !isCnpjComplete);

  return (
    <>
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={"20px"}
        textAlign={"center"}
        w={{ base: "80%", md: "55%", lg: "55%", xl: "80%" }}
      >
        <Text
          fontSize={"18px"}
          w={"fit-content"}
          color={theme.text}
          fontWeight={"medium"}
        >
          Esqueceu sua senha?
        </Text>

        <Flex mt={"15px"}>
          <Text fontSize={"13px"} color={theme.text}>
            Informe seu <strong>CPF/CNPJ</strong> para criar uma nova senha:
          </Text>
        </Flex>
      </Flex>

      <Flex
        flexDir={"column"}
        w={{ base: "80%", md: "55%", lg: "55%", xl: "80%" }}
        h={"85%"}
        align={"center"}
        justify={"center"}
      >
        <Flex flexDir={"column"}>
          <InputCPF
            isLabel
            value={documentMask(document)}
            onChange={(e) => {
              setDocument(e.target.value);
              handleCleanError();
            }}
            iconClick={() => {
              setDocument("");
              handleCleanError();
            }}
            error={error.errorDocument}
          />
        </Flex>

        <Flex mt={"15px"}>
          <Button
            title={buttonText}
            onClick={() => {
              setDigits("");
              handleEnter();
            }}
            disabled={loading || !document || validDocument}
            error={error.errorDocument}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          h={{ md: "80px", xl: "fit-content" }}
          mt={{ base: "15px", md: "10px", xl: "25px" }}
        >
          {error.errorDocument && (
            <Box
              title={
                error.errorTitle ||
                "Utilize um CPF/CNPJ com formato válido para ativar seu cadastro."
              }
              body={
                <Styled.ContainerErrorForgotPassword>
                  {messageBox(
                    error.errorMessage,
                    setPage,
                    setErrorForgotPassword
                  )}
                </Styled.ContainerErrorForgotPassword>
              }
            />
          )}
        </Flex>
      </Flex>

      <Flex
        flexDir={{ base: "column", md: "row" }}
        align={"center"}
        justifyContent={"center"}
        fontSize={"14px"}
        mb={{ base: "8px", lg: "20px", xl: "36px" }}
        mt={"18px"}
      >
        <Text
          w={"fit-content"}
          fontWeight={"medium"}
          color={theme.primary}
          ml={"4px"}
          cursor={"pointer"}
          onClick={onBack}
        >
          Retornar ao login
        </Text>
      </Flex>

      <Helper />
    </>
  );
};

export default ForgotPasswordComponent;
