import React, { useState, useEffect } from "react";
import * as Styled from "../../styles";
import { FaRegUser } from "react-icons/fa";
import Button from "@components/common/Button";
import {
  IAccountsComponents,
  ValuesFormikAccountPhysical,
} from "src/@types/Sales";
import { useFormik } from "formik";
import { isEmail } from "@helpers/emailCheck";
import { LoginContext } from "@context/loginContext";
import enterprisesServiceInstance from "@services/Enterprises/enterprisesService";
import Alert from "@components/common/Alert";
import salesService from "@services/Sales/SalesService";
import Spinner from "@components/common/Spinner";
import { UsuallyContext } from "@context/usuallyContex";
import { SectionMainInformations } from "../../components/SectionPhysical/SectionMainInformationsComponent";
import { SectionProductsInterest } from "../../components/SectionProductsInterestComponent";
import { phoneMask } from "@helpers/format";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { format, parse } from "date-fns";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { useOnlyMessageToast } from "@hook/useMessageToast";

const AccountsPhysicalComponents = ({
  onBack,
  isCreateAccount,
  setCpfCreateAccount,
  setMandatoryCreateAccount,
}: IAccountsComponents) => {
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const regionalUser: any = localStorage.getItem("@regional");
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const regional = regionalUser;
  const { signOut } = React.useContext(LoginContext);
  const {
    setSuccessCreateAccountSales,
    setNameCreateAccountSales,
    setAccountIdSalesforce,
    setTypeAccount,
  } = React.useContext(UsuallyContext);

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [treatment, setTreatment] = useState("");
  const [commercialRegional, setCommercialRegional] = useState("");
  const [businessUnity, setBusinessUnity] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [income, setIncome] = useState("");
  const [bestHours, setBestHours] = useState("");
  const [purposePurchase, setPurposePurchase] = useState("");
  const [detailsLive, setDetailsLive] = useState("");
  const [numberKids, setNumberKids] = useState("");
  const [numberRooms, setNumberRooms] = useState("");
  const [numberVacancy, setNumberVacancy] = useState("");
  const [typeWork, setTypeWork] = useState("");
  const [require, setRequire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = useState(false);
  const [dataEnterprises, setDataEnterprises] = useState([]);

  const [selectProfession, setSelectProfession] = useState("");
  const [loadingProfession, setLoadingProfession] = useState(false);
  const [dataProfession, setDataProfession] = useState([]);
  const [idProfession, setIdProfession] = useState("");

  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [dataCampaign, setDataCampaign] = useState([]);
  const [idEnterprises, setIdEnterprises] = useState("");
  const [idCampaign, setIdCampaign] = useState("");
  const [error, setError] = useState({
    email: false,
    telefone: false,
    cpf: false,
  });
  const [campaign, setCampaign] = useState("");
  const [selectCampaign, setSelectCampaign] = useState("");
  const [mainEnterprises, setMainEnterprises] = useState("");
  const [selectMainEnterprises, setSelectMainEnterprises] = useState("");
  const [originAccount, setOriginAccount] = useState("");

  const isWhatsAppComplementary = selectedValues.includes(
    "WhatsappComplementary"
  );
  const isCelularComplementary = selectedValues.includes(
    "CelularComplementary"
  );
  const isWhatsAppPrincipal = selectedValues.includes("Whatsapp");
  const isCelularPrincipal = selectedValues.includes("Celular");

  const [dateIssue, setDateIssue] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [civilStatus, setCivilStatus] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [weddingDate, setWeddingDate] = React.useState("");
  const [marriageRegime, setMarriageRegime] = React.useState("");

  const initialValues = {
    nameAccount: "",
    mainPhone: "",
    mainEmail: "",
    complementaryPhone: "",
    complementaryEmail: "",
    comments: "",

    cpf: "",
    rg: "",
    orgaoExpedidorRG: "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => handleSubmitFormik(values),
  });

  const handleDateFormat = (date: string) => {
    if (!date) {
      return null;
    }
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'00:00:00'Z'");
    return formattedDate;
  };

  const handleSubmitFormik = async (value: ValuesFormikAccountPhysical) => {
    setLoading(true);
    setMandatoryCreateAccount(false);

    const bodyIsCreateAccount = {
      idCorretorSalesforce: idCorretor,
      tipoRegistro: "Cliente - Pessoa Física",
      nomeDaConta: value?.nameAccount,
      regional: regional,
      regionalComercial: commercialRegional,
      telefonePrincipal: phoneMask(value?.mainPhone),
      emailPrincipal: value?.mainEmail,
      unidadeDeNegocio: businessUnity,
      telefoneComplementarEhWhatsapp: isWhatsAppComplementary,
      telefoneComplementarEhCelular: isCelularComplementary,
      telefonePrincipalEhWhatsapp: isWhatsAppPrincipal,
      telefonePrincipalEhCelular: isCelularPrincipal,
      cpf: value?.cpf,
      rg: value?.rg,
      estadoCivil: civilStatus,
      nacionalidade: nationality,
      profissao: profession,
      orgaoExpedidorRG: value?.orgaoExpedidorRG,
      dataExpedicaoRG: handleDateFormat(dateIssue),
      dataNascimento: handleDateFormat(dateBirth),
      criarOportunidade: isCreateAccount ? false : true,
      sexo: sex,
      dataCasamento: handleDateFormat(weddingDate),
      regimeCasamento: marriageRegime,
    };

    const body = {
      idCorretorSalesforce: idCorretor,
      tipoRegistro: "Cliente - Pessoa Física",
      origemDaConta: originAccount,
      tratamento: treatment,
      nomeDaConta: value?.nameAccount,
      regional: regional,
      regionalComercial: commercialRegional,
      telefonePrincipal: phoneMask(value?.mainPhone),
      emailPrincipal: value?.mainEmail,
      unidadeDeNegocio: businessUnity,
      renda: income,
      telefoneComplementar: phoneMask(value?.complementaryPhone),
      emailComplementar: value?.complementaryEmail,
      campanha: idCampaign,
      melhorHorarioDeAtendimento: bestHours,
      finalidadeDaCompra: purposePurchase,
      detalhesPodeMorar: detailsLive,
      tipoDeTrabalho: typeWork,
      numeroDeFilhos: numberKids,
      observacoes: value.comments,
      telefoneComplementarEhWhatsapp: isWhatsAppComplementary,
      telefoneComplementarEhCelular: isCelularComplementary,
      telefonePrincipalEhWhatsapp: isWhatsAppPrincipal,
      telefonePrincipalEhCelular: isCelularPrincipal,
      empreendimentoDeInteressePrincipal: idEnterprises,
      numeroDeQuartos: numberRooms,
      numeroDeVagas: numberVacancy,
      cpf: value?.cpf,
      rg: value?.rg,
      estadoCivil: civilStatus,
      nacionalidade: nationality,
      profissao: profession,
      orgaoExpedidorRG: value?.orgaoExpedidorRG,
      dataExpedicaoRG: handleDateFormat(dateIssue),
      dataNascimento: handleDateFormat(dateBirth),
      criarOportunidade: isCreateAccount ? false : true,
      sexo: sex,
      dataCasamento: handleDateFormat(weddingDate),
      regimeCasamento: marriageRegime,
    };

    try {
      const response = await salesService.createPersonPhisycal(
        isCreateAccount ? bodyIsCreateAccount : body
      );

      if (
        response?.status === 400 &&
        (response?.data?.emailJaUtilizado ||
          response?.data?.telefoneJaUtilizado ||
          response?.data?.cpfCnpjJaUtilizado)
      ) {
        setError((prevState) => ({
          ...prevState,
          email: response?.data?.emailJaUtilizado,
          telefone: response?.data?.telefoneJaUtilizado,
          cpf: response?.data.cpfCnpjJaUtilizado,
        }));
        setLoading(false);
        return;
      }

      if (response?.status === 400) {
        const messageErro = response?.data?.erro
        setLoading(false);
        displayMessageError({ message: messageErro ?? "Erro ao criar conta!" });
        return;
      }

      if (response?.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      if (isCreateAccount) {
        onBack();
        displayMessage("Conta criada com sucesso!");
        setCpfCreateAccount(value?.cpf);
        setMandatoryCreateAccount(true);
      } else {
        setSuccessCreateAccountSales(true);
        setNameCreateAccountSales(value?.nameAccount);
        setAccountIdSalesforce(response?.data.id);
      }
      setTypeAccount("");
      setLoading(false);
    } catch (error) {
      console.log("ErrorFormik", error);
      setLoading(false);
    }
  };

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesServiceInstance.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleGetCampaign = async () => {
    setLoadingCampaign(true);

    try {
      const response = await salesService.getCampaign();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCampaign(false);
        setDataCampaign([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCampaign(false);
        setIsUnauthorized(true);
        setDataCampaign([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataCampaign(dataArray);
      setLoadingCampaign(false);
    } catch (error) {
      setLoadingCampaign(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleGetProffesion = async () => {
    setLoadingProfession(true);

    try {
      const response = await oportunityServiceInstance.getProfission();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingProfession(false);
        setDataProfession([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingProfession(false);
        setIsUnauthorized(true);
        setDataProfession([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataProfession(dataArray);
      setLoadingProfession(false);
    } catch (error) {
      setLoadingProfession(false);
      console.log("errorHandleGetProffesion", error);
    }
  };

  const nameParts = formik.getFieldProps("nameAccount").value?.split(" ");
  const filteredNameParts = nameParts?.filter(
    (part: string) => part?.trim() !== ""
  );

  useEffect(() => {
    const marriedStatusNotFilled =
      civilStatus === "Casado(a)" &&
      (marriageRegime === null ||
        marriageRegime === "" ||
        weddingDate === null ||
        weddingDate === "");

    if (isCreateAccount) {
      const isRequirement =
        !formik.getFieldProps("nameAccount").value ||
        /\d/.test(formik.getFieldProps("nameAccount").value) ||
        /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(
          formik.getFieldProps("nameAccount").value
        ) ||
        (filteredNameParts?.length ?? 0) < 2 ||
        !commercialRegional ||
        !businessUnity ||
        marriedStatusNotFilled ||
        !phoneMask(formik.getFieldProps("mainPhone").value) ||
        phoneMask(formik?.getFieldProps("mainPhone")?.value)?.length < 15 ||
        !formik.getFieldProps("mainEmail").value ||
        (!isEmail(formik.getFieldProps("mainEmail").value) &&
          formik.getFieldProps("mainEmail").value) ||
        (!isEmail(formik.getFieldProps("complementaryEmail").value) &&
          formik.getFieldProps("complementaryEmail").value);

      setRequire(isRequirement);
    } else {
      const isRequirement =
        !formik.getFieldProps("nameAccount").value ||
        /\d/.test(formik.getFieldProps("nameAccount").value) ||
        /[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/.test(
          formik.getFieldProps("nameAccount").value
        ) ||
        (filteredNameParts?.length ?? 0) < 2 ||
        !originAccount ||
        !commercialRegional ||
        !businessUnity ||
        marriedStatusNotFilled ||
        !phoneMask(formik.getFieldProps("mainPhone").value) ||
        phoneMask(formik?.getFieldProps("mainPhone")?.value)?.length < 15 ||
        !formik.getFieldProps("mainEmail").value ||
        (!isEmail(formik.getFieldProps("mainEmail").value) &&
          formik.getFieldProps("mainEmail").value) ||
        (!isEmail(formik.getFieldProps("complementaryEmail").value) &&
          formik.getFieldProps("complementaryEmail").value);

      setRequire(isRequirement);
    }
  }, [
    originAccount,
    businessUnity,
    filteredNameParts,
    formik,
    commercialRegional,
    civilStatus,
    marriageRegime,
    weddingDate,
    isCreateAccount,
  ]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {!isCreateAccount && (
        <Styled.ContainerTitlePage>
          <Styled.ContainerTitle>
            <FaRegUser />
            <Styled.TitlePage>Contas</Styled.TitlePage>
          </Styled.ContainerTitle>
        </Styled.ContainerTitlePage>
      )}

      <Styled.ContainerBoxAccounts isRelationship={isCreateAccount}>
        {!isCreateAccount && (
          <Styled.ContainerTitleBox>
            <Styled.ContainerTitleWithCloseButton>
              <span>Nova Conta: Cliente - Pessoa Física</span>
            </Styled.ContainerTitleWithCloseButton>

            <Styled.IconAiOutlineCloseCircle onClick={onBack} />
          </Styled.ContainerTitleBox>
        )}

        <Styled.ContainerBoxInfo>
          <SectionMainInformations
            setIdCampaign={setIdCampaign}
            formik={formik}
            error={error}
            setError={setError}
            originAccount={originAccount}
            setOriginAccount={setOriginAccount}
            treatment={treatment}
            setTreatment={setTreatment}
            regional={regional}
            commercialRegional={commercialRegional}
            setCommercialRegional={setCommercialRegional}
            selectedCheckBox={selectedValues}
            setSelectedCheckBox={setSelectedValues}
            businessUnity={businessUnity}
            setBusinessUnity={setBusinessUnity}
            income={income}
            setIncome={setIncome}
            campaign={campaign}
            setCampaign={setCampaign}
            selectCampaign={selectCampaign}
            setSelectCampaign={setSelectCampaign}
            loadingCampaign={loadingCampaign}
            bestHours={bestHours}
            setBestHours={setBestHours}
            purposePurchase={purposePurchase}
            setPurposePurchase={setPurposePurchase}
            detailsLive={detailsLive}
            setDetailsLive={setDetailsLive}
            typeWork={typeWork}
            setTypeWork={setTypeWork}
            numberKids={numberKids}
            setNumberKids={setNumberKids}
            dataCampaign={dataCampaign}
            getCampaign={handleGetCampaign}
            civilStatus={civilStatus}
            setCivilStatus={setCivilStatus}
            dateBirth={dateBirth}
            setDateBirth={setDateBirth}
            dateIssue={dateIssue}
            setDateIssue={setDateIssue}
            nationality={nationality}
            setNationality={setNationality}
            profession={profession}
            setProfession={setProfession}
            selectProfession={selectProfession}
            setSelectProfession={setSelectProfession}
            getProfession={handleGetProffesion}
            loadingProfession={loadingProfession}
            dataProfession={dataProfession}
            setIdProfession={setIdProfession}
            isCreateAccount={isCreateAccount}
            sex={sex}
            setSex={setSex}
            marriageRegime={marriageRegime}
            setMarriageRegime={setMarriageRegime}
            weddingDate={weddingDate}
            setWeddingDate={setWeddingDate}
          />
        </Styled.ContainerBoxInfo>

        {!isCreateAccount && (
          <Styled.ContainerBoxInfo>
            <SectionProductsInterest
              setIdEnterprise={setIdEnterprises}
              selectMainEnterprises={selectMainEnterprises}
              setSelectMainEnterprises={setSelectMainEnterprises}
              mainEnterprises={mainEnterprises}
              setMainEnterprises={setMainEnterprises}
              loadingEnterprises={loadingEnterprises}
              numberRooms={numberRooms}
              setNumberRooms={setNumberRooms}
              numberVacancy={numberVacancy}
              setNumberVacancy={setNumberVacancy}
              dataEnterprises={dataEnterprises}
              getEnterprises={handleGetEnterprises}
            />
          </Styled.ContainerBoxInfo>
        )}

        <Styled.DividerBoxInput />
        <Styled.ContainerFooterBox>
          <Styled.ContainerMandatory>
            <span>(*) Campo de preenchimento obrigatório</span>
          </Styled.ContainerMandatory>
          <Styled.ContainerButton>
            <Button error title="CANCELAR" onClick={onBack} />

            <Button
              title={loading ? <Spinner /> : "CRIAR CONTA"}
              onClick={formik.handleSubmit}
              disabled={require || loading || error.email || error.telefone}
            />
          </Styled.ContainerButton>
        </Styled.ContainerFooterBox>
      </Styled.ContainerBoxAccounts>
    </>
  );
};

export default AccountsPhysicalComponents;
