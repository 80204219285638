import styled from "styled-components";
import { theme } from "src/styles/theme";
import { device } from "src/styles/variable";

interface IFase {
  faseOp?: boolean;
}

export const ContainerToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 80px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerToastButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 110px;
  background: white;
  padding: 10px;
  border-radius: 8px;
`;

export const ContainerTitleSuccessToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  justify-content: center;
  height: 40px;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerHeaderToast = styled.div`
  display: flex;
  width: 100%;
  height: 16%;
  z-index: 1;

  > span {
    font-size: 12px;
    font-weight: bold;
    color: ${theme.disable};
  }
`;

export const ContainerMessageToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  justify-content: center;
  height: 40px;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;
export const ContainerMessageBodyToast = styled.div`
  display: flex;
  width: 100%;
  height: 35%;

  > span {
    font-size: 15px;
    color: ${theme.text};
  }
`;

export const ContainerButtonToast = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
`;

export const ContainerActivityCreditRating = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 10px;
  border-radius: 8px;
  margin-top: 15px;
`;

export const AreaQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitleNotify = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 30%;

  > span {
    font-size: 17px;
    font-weight: bold;
    color: ${theme.text};
  }
`;

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 110px;
  justify-content: space-between;

  > span {
    font-size: 12px;
    color: ${theme.text};
    margin-top: 10px;
  }
`;

export const ContainerBoxInputsCreditRating = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;

  @media print {
    grid-row-gap: 25px;
  }

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: fit-content;
  }
`;


export const ContainerTitlePage = styled.section`
  display: flex;
  width: 99%;
  height: 20%;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const ContainerUpdateButton = styled.div`
  display: flex;
  width: 48px;
  align-self: flex-end;
  margin-top: 4px;
`;

export const ContainerSendButton = styled.div`
  display: flex;
  width: fit-content;
  align-self: flex-end;
  margin-top: 4px;
  margin-left: 16px;
`;
export const ContainerTitle = styled.div`
  display: flex;
  gap: 6px;
  gap: 6px;

  @media ${device.mobileL} {
    width: 100%;
  }

  > svg {
    display: flex;
    width: fit-content;
    font-size: 26px;
  }
`;

export const ContainerButtonEditHeader = styled.div`
  display: flex;
  width: 30%;
`;
export const ContainerButtonUpdateHeader = styled.div`
  display: flex;
  width: 48px;
  > svg {
    width: fit-content;
    color: white;
    font-size: 24px;
  }
`;
export const ContainerAnyButtons = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  gap: 6px;

  @media ${device.laptopXL} {
    width: 30%;
  }
  @media ${device.laptop} {
    width: 30%;
  }
  @media ${device.tablet} {
    width: 30%;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const TitlePage = styled.span`
  width: 90%;
  font-size: 28px;
  font-weight: 500;
  color: ${theme.text};

  @media ${device.laptop} {
    width: 70%;
  }
  @media ${device.mobileL} {
    width: 100%;
    font-size: 25px;
    margin-bottom: 8px;
  }
`;

export const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const ContainerRelationship = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const ContainerQuotes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;

export const ContainerHeaderReasonsDisapproval = styled.div`
  display: flex;
  padding: 4px;
  gap: 6px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerTitleReasonsDisapproval = styled.div`
  display: flex;
  padding: 4px;
  padding-left: 12px;
  border-radius: 8px;
  background: ${theme.backgroundGray};
  align-items: center;

  > span {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ContainerTableReasonsDisapproval = styled.div`
  display: flex;
  padding: 4px;
  padding-left: 10px;
`;

export const ContainerHeaderWithButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const TitleActivity = styled.div`
  display: flex;

  > h1 {
    font-size: 13px;
    flex: 1;
    height: 35px;
    color: ${theme.text};
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
    border-radius: 8px;
    background-color: ${theme.disable100};
  }
`;
export const TitleCreateRelationship = styled.div`
  display: flex;

  > h1 {
    font-size: 13px;
    flex: 1;
    height: 35px;
    color: ${theme.text};
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
    border-radius: 8px;
    background-color: ${theme.disable100};

    @media ${device.mobileL} {
      height: 80px;
    }
  }
`;

export const ContainerHeaderQuotes = styled.div`
  display: flex;
  padding: 4px;
  gap: 6px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerTableQuotes = styled.div`
  display: flex;
  padding: 4px;
  padding-left: 10px;
`;

export const ContaineButtonsHeader = styled.div`
  display: flex;
  width: 30%;
  gap: 8px;

  @media ${device.laptopXL} {
    width: 20%;
  }
  @media ${device.laptop} {
    width: 35%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const ContaineButtonsCreateHeader = styled.div`
  display: flex;
  width: 38%;
  gap: 8px;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const UpdateButton = styled.div`
  display: flex;
  width: 15%;
  gap: 8px;
  margin-right: 16px;

  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 35%;
  }
`;

export const TitleButon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  > svg {
    font-size: 17px;
    width: fit-content;
    color: "white";
  }
`;

export const TitleRelationship = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const ContainerTitleRelationship = styled.div`
  display: flex;
  padding: 4px;
  padding-left: 12px;
  border-radius: 8px;
  background: ${theme.backgroundGray};
  align-items: center;

  > span {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ButtonTitleRelationship = styled.div`
  display: flex;
  width: 180px;
`;

export const ContainerRelationshipLoading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 10px;
`;

export const ContainerTitleLabelRelationship = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
`;

export const ContainerRelationshipEmpty = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.text};
    font-size: 18px;
  }
`;

export const TitleLabelRelationshipLeft = styled.span`
  width: 80%;
  font-size: 10px;
  color: ${theme.text};

  @media ${device.laptopXL} {
    width: 90%;
  }

  @media ${device.laptop} {
    width: 60%;
  }
`;
export const TitleLabelRelationshipRight = styled.span`
  width: 21%;
  font-size: 10px;
  color: ${theme.text};
`;
export const ContainerTitleLabelRelationshipRight = styled.div`
  display: flex;
  width: 65%;

  @media ${device.laptopXL} {
    width: 46%;
  }
  @media ${device.laptop} {
    width: 85%;
  }
`;

export const ContainerTodoRelationship = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  @media ${device.mobileL} {
    overflow: auto;
  }
`;

export const ContainerBoxRelationship = styled.div`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  background: #dde1e8b3;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobileL} {
    width: 600px;
  }
`;

export const ContainerIconTextHistoric = styled.div`
  display: flex;
  width: 80%;

  @media ${device.laptopXL} {
    width: 90%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
`;

export const ContainerIconHistoric = styled.div`
  display: flex;
  width: 50px;
  height: 40px;
  background: ${theme.primary};
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  > svg {
    color: white;
    font-size: 26px;
  }
`;

export const ContainerTextHistoric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-left: 5px;
`;
export const TitleTextHistoric = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
export const SubTitleTextHistoric = styled.div`
  font-size: 11px;
`;

export const ContainerDataStatus = styled.div`
  display: flex;
  width: 45%;

  @media ${device.laptopXL} {
    width: 33%;
  }

  @media ${device.laptop} {
    width: 60%;
  }
`;

export const ContainerIconTextDataStatus = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ContainerTexDataStatus = styled.div`
  display: flex;
`;

export const ContainerBoxTexDataStatus = styled.div`
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
`;

export const BoxDivider = styled.div`
  display: flex;
  width: fit-content;
`;
export const DividerActivity = styled.div`
  display: flex;
  width: 2px;
  height: 98%;
  background: ${theme.disable};
  margin-left: 5px;
  margin-right: 5px;
`;

export const ContainerButtonDataStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonIconDataStatus = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
`;
export const AreaButtonIconDataTasksCalls = styled.div<IFase>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 1px;
  cursor: ${({ faseOp }) => (faseOp ? "default" : "pointer")};

  > span {
    width: fit-content;
    font-size: 12px;
    text-decoration: underline;
    color: ${({ faseOp }) => (faseOp ? theme.disable : theme.text)};
  }

  > svg {
    width: fit-content;
    color: ${({ faseOp }) => (faseOp ? theme.disable : theme.text)};
    font-size: 22px;
  }
`;
export const AreaButtonIconDelete = styled.div<IFase>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid ${({ faseOp }) => (faseOp ? theme.disable : theme.error)};
  gap: 1px;
  cursor: ${({ faseOp }) => (faseOp ? "not-allowed" : "pointer")};

  > svg {
    width: fit-content;
    color: ${({ faseOp }) => (faseOp ? theme.disable : theme.error)};
    font-size: 18px;
  }
`;

export const TitleTexDataStatus = styled.div`
  display: flex;
  font-size: 12px;
  color: ${theme.text};
  justify-content: center;
  text-align: center;
`;

export const ContainerAccordion = styled.div`
  display: flex;
`;

export const ContainerLoadingView = styled.div`
  display: flex;
  width: 100%;
  min-height: 250px;
  align-items: center;
  justify-content: center;
`;

export const AreaSchedule = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContainerTitleViewRelationship = styled.div`
  display: flex;
  gap: 6px;

  > span {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;
export const ContainerSchedule = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;
export const ContainerTaskCall = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 4px;
  border: 1px solid ${theme.disable100};
  border-radius: 8px;
`;
export const ContainerButtonsHeaderTaskCall = styled.div`
  display: flex;
  gap: 10px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const TitleSchedule = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.text};
`;

export const ContainerDisplaySearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 60%;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-top: 10px;
  }
  @media ${device.mobileL} {
    margin-top: 20px;
    width: 96%;
  }
`;

export const ContainerDisplayCreateRelationship = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media print {
    display: none;
  }

  @media ${device.laptop} {
    width: 60%;
    margin-top: 2px;
  }
  @media ${device.tablet} {
    width: 70%;
    margin-top: 2px;
  }
  @media ${device.mobileL} {
    margin-top: 2px;
  }
`;

export const TitleDisplay = styled.span`
  display: flex;
  font-size: 13px;

  > p {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: ${theme.error};
    padding-left: 5px;
  }
`;
export const TitleDisplayRequired = styled.span`
  display: flex;
  width: fit-content;
  font-size: 11px;
  font-weight: bold;
  color: ${theme.error};
`;

export const ContainerRadioButtonSearch = styled.div`
  display: flex;
  width: 262px;
  height: 50%;
  gap: 10px;
`;

export const ContainerDisplayInput = styled.div`
  display: flex;
  gap: 15px;
  height: 50%;
  margin-top: 5px;

  > div {
    width: 100%;
  }
  > button {
    width: 40%;
  }
`;

export const ContainerInputsSchedule = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  gap: 6px;
`;
export const ContainerInputsCreateRelationship = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
  margin-top: 8px;
`;

export const ContainerInputsRelationship = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  gap: 6px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerInputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const ContainerInputsGridSolo = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const ContainerInputsGridDuo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 5px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  margin-top: 10px;
`;
export const ButtonCreateAccount = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: ${theme.primary};
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  gap: 4px;
  opacity: 0.8;
  transition: opacity ease 0.2s;
  cursor: pointer;
  outline: none;

  > span {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }

  > svg {
    width: fit-content;
    color: white;
    font-size: 22px;
  }
`;

export const ContainerInputsSolo = styled.div`
  display: flex;
  width: 33%;
`;

export const ContainerButtonEdit = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;

  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 15px;
  }
`;
export const AreaButtonEdit = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
`;

export const TitleButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: fit-content;
    color: white;
    font-size: 24px;
  }
`;

export const UpdateTitleButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: fit-content;
    font-size: 20px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 300px;
`;

export const DividerBoxInput = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  background: ${theme.disable};
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerFooterBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const ContainerDuoMandatory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  > span {
    display: flex;
    color: ${theme.error};
    font-size: 13px;
    font-weight: bold;
    align-items: center;

    > svg {
      width: fit-content;
      height: fit-content;
    }
  }
`;

export const ContainerButtonFooter = styled.div`
  display: flex;
  width: 70%;
  height: fit-content;
  gap: 10px;

  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 15px;
  }
`;
export const ContainerButtonFooterEdit = styled.div`
  display: flex;
  width: 80%;
  height: fit-content;
  gap: 10px;

  @media ${device.laptop} {
    width: 85%;
  }
  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-top: 15px;
  }
`;

export const ContainerActivitySubTab = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  gap: 6px;
  border-radius: 8px;
  margin-top: 8px;

  @media ${device.laptop} {
    padding: 0;
  }
`;
