import { ReactNode } from "react";
import {
  Box,
  Modal as CkModal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { theme } from "src/styles/theme";
interface IModal {
  onOpen: boolean;
  isModalDetail?: boolean;
  title: string;
  subtitle?: string;
  children: ReactNode;
  onClose?: any;
  colorBgIcon?: string;
  iconTitle?: any;
  isCreateAccount?: boolean;
  relationship?: any[];
  currentIndexClient?: number;
}

const Modal = ({
  onOpen,
  title,
  subtitle,
  children,
  onClose,
  isModalDetail,
  colorBgIcon,
  iconTitle,
  isCreateAccount,
  relationship,
  currentIndexClient = 0,
}: IModal) => {
  return (
    <CkModal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClose}
      size={isCreateAccount ? "6xl" : "3xl"}
    >
      <ModalOverlay />
      <ModalContent minH={"350px"}>
        <ModalHeader>
          <Box>
            {!relationship || relationship.length === 0 ? (
              <Text fontSize={"11px"} color={theme.text50}>
                Bora Vender
              </Text>
            ) : (
              <Flex
                flexDir={"column"}
                h={"40px"}
                alignItems={"center"}
                gap={"6px"}
              >
                <Text fontSize={"11px"}>
                  <strong>
                    Etapa {currentIndexClient + 1} de {relationship.length}
                  </strong>{" "}
                  -{" "}
                  {currentIndexClient === 0
                    ? "Comprador principal"
                    : "Compositor de renda"}
                </Text>
                <Flex gap={"6px"}>
                  {relationship?.map((_, index) => (
                    <Box
                      key={index}
                      w={"50px"}
                      h={"6px"}
                      bg={
                        index < currentIndexClient
                          ? theme.primary
                          : index === currentIndexClient
                            ? theme.primary
                            : theme.disable100
                      }
                      borderRadius={"8px"}
                    />
                  ))}
                </Flex>
              </Flex>
            )}

            {isModalDetail ? (
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  display={"flex"}
                  w={"36px"}
                  h={"32px"}
                  borderRadius={"8px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  bg={colorBgIcon}
                >
                  <Text>{iconTitle}</Text>
                </Box>
                <Text
                  fontSize={"18px"}
                  fontWeight={"bold"}
                  color={theme.text}
                  ml={"6px"}
                >
                  {title}
                </Text>
              </Box>
            ) : (
              <>
                <Text fontSize={"18px"} fontWeight={"bold"} color={theme.text}>
                  {title}
                </Text>

                <Text fontSize={"11px"} color={theme.text}>
                  {subtitle}
                </Text>
              </>
            )}
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={isCreateAccount ? 1 : 10} pt={0}>
          {children}
        </ModalBody>
      </ModalContent>
    </CkModal>
  );
};

export default Modal;
