import React, { startTransition } from "react";
import * as Styled from "../../styles";
import InputSearch from "@components/Sales/Inputs/InputSearch";
import salesService from "@services/Sales/SalesService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { BsFillPersonVcardFill } from "react-icons/bs";
import TableMyOportunity from "@components/Sales/TableMyOportunity";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionFase, optionOportunity } from "@helpers/optionSelect";
import Spinner from "@components/common/Spinner";
import { formatDatePtBr } from "@helpers/format";
import InputText from "@components/Sales/Inputs/InputText";
import Button from "@components/common/Button";
import { RxUpdate } from "react-icons/rx";
import { TableRow } from "src/@types/Sales";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

const MyOportunityComponent = ({ navigation }: any) => {
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const { signOut } = React.useContext(LoginContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [dataOportunity, setDataOportunity] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [newSearch, setNewSearch] = React.useState("");
  const [fase, setFase] = React.useState("");
  const [oportunity, setOportunity] = React.useState("");
  const [dateStart, setDateStart] = React.useState("");
  const [dateEnd, setDateEnd] = React.useState("");

  function handleFlagOportunity(oportunity: string) {
    if (oportunity === "Nova") return true;
    if (oportunity === "Antiga") return false;
    if (oportunity === "Todas") return null;
    return null;
  }

  const handleGetOportunity = async () => {
    setLoading(true);
    try {
      const response = await salesService.getOportunity({
        idCorretorSalesforce: idCorretor,
        DataInicio: dateStart,
        DataFim: dateEnd,
        FlagNovoConceito: handleFlagOportunity(oportunity),
      });

      if (response.status === 401) {
        setIsUnauthorized(true);
        setDataOportunity([]);
        setLoading(false);
        return;
      }
      if (response.status === 400) {
        setDataOportunity([]);
        setLoading(false);
        return;
      }

      setDataOportunity(response);
      setNewSearch(search);
      setLoading(false);
    } catch (error) {
      console.log("errorHandleGetSales", error);
      setLoading(false);
    }
  };

  const filteredRows = React.useMemo(() => {
    if (!dataOportunity) return [];
    return dataOportunity?.filter(
      (row: TableRow) =>
        (row?.nomeDaConta?.toLowerCase()?.includes(newSearch?.toLowerCase()) ||
          row?.opportunityIdSalesforce
            ?.toLowerCase()
            ?.includes(newSearch?.toLowerCase())) &&
        (fase === "" ||
          fase === "Todas" ||
          row?.fase?.toLowerCase() === fase.toLowerCase())
    );
  }, [dataOportunity, fase, newSearch]);

  React.useEffect(() => {
    if (
      idCorretor &&
      ((dateStart.length === 10 && dateEnd.length === 10) ||
        (dateStart.length === 0 && dateEnd.length === 0))
    ) {
      handleGetOportunity();
    }
  }, [idCorretor, dateStart, dateEnd, oportunity]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamante para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Flex
        w={"99%"}
        h={"80px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={{ base: "20px", md: "0px" }}
      >
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
          <Text fontSize={28} fontWeight={"semibold"} color={theme.text}>
            Minhas oportunidades
          </Text>
          <Text fontSize={14} color={theme.text50}>
            Selecione uma oportunidade para ver mais detalhes, criar
            agendamentos e mais
          </Text>
        </Box>

        <Flex w={140}>
          <Button
            title={
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"4px"}
              >
                <Icon fontSize="16px" color="white" as={RxUpdate} />
                Atualizar
              </Box>
            }
            onClick={() => handleGetOportunity()}
          />
        </Flex>
      </Flex>

      <Flex
        flexDir={"column"}
        w={"99%"}
        h={"120px"}
        bg={"white"}
        borderRadius={"8px"}
        mt={"8px"}
        gap={"6px"}
        p={"10px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex>
          <Text fontWeight={"semibold"}>Pesquisa e filtros</Text>
        </Flex>

        <Flex gap={"6px"}>
          <Styled.ContainerSearchInputOportunity>
            <InputSearch
              isOportunity
              label="Nome do cliente ou ID da oportunidade"
              placeholder={`Pesquisar...`}
              value={search}
              onChange={(e) => {
                startTransition(() => {
                  setSearch(e.target.value);
                });
              }}
            />
          </Styled.ContainerSearchInputOportunity>

          <Styled.ContainerSelectInputOportunity>
            <InputSelect
              widht="17%"
              widhtMobile={"45%"}
              title="Fase"
              label={fase || "Selecione"}
              isSelected={fase}
              options={optionFase}
              selectedOption={fase}
              onChange={(e) => setFase(e.currentTarget.value)}
            />
            <InputSelect
              widht="17%"
              widhtMobile={"45%"}
              title="Oportunidade"
              label={oportunity || "Selecione"}
              isSelected={oportunity}
              options={optionOportunity}
              selectedOption={oportunity}
              onChange={(e) => setOportunity(e.currentTarget.value)}
            />
          </Styled.ContainerSelectInputOportunity>

          <Styled.ContainerSelectInputDateOportunity>
            <Styled.LabelInputDateOportunity>
              Filtrar por data
            </Styled.LabelInputDateOportunity>
            <div>
              <InputText
                label=""
                name="dateStart"
                placeholder={`De __/__/___`}
                value={dateStart}
                onChange={(e) => setDateStart(formatDatePtBr(e.target.value))}
                maxLength={10}
                iconSee={false}
              />
              <InputText
                label=""
                name="dateEnd"
                placeholder={`Até __/__/___`}
                value={dateEnd}
                onChange={(e) => setDateEnd(formatDatePtBr(e.target.value))}
                maxLength={10}
                iconSee={false}
              />
            </div>
          </Styled.ContainerSelectInputDateOportunity>
        </Flex>
      </Flex>

      <Flex
        flexDir={"column"}
        mt={"12px"}
        bg={"white"}
        borderRadius={"8px"}
        w={"99%"}
        h={{ xl: "320px", "2xl": "620px" }}
      >
        <>
          {loading ? (
            <Styled.ContainerSpinner>
              <Spinner />
            </Styled.ContainerSpinner>
          ) : (
            <Flex
              flexDir={"column"}
              w={"100%"}
              h={"fit-content"}
              overflow={"auto"}
              borderRadius={"8px"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.primary,
                  borderRadius: "20px",
                },
              }}
            >
              {filteredRows.length === 0 ? (
                <Styled.ContainerSearchMessageUnityModal>
                  {filteredRows.length === 0 && newSearch === "" ? (
                    <>
                      <Styled.TitleSection>
                        Pesquise utilizando as seguintes sugestões
                      </Styled.TitleSection>

                      <Styled.BodySearchUnityModalMessage>
                        - Selecione um tipo de pesquisa entre: Nome da conta ou
                        Id da oportunidade;
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Certifique-se de que os dados estejam digitados
                        corretamente;
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Tente escolher palavras-chave;
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Use termos mais genéricos, ou seja, com um conceito
                        mais amplo
                      </Styled.BodySearchUnityModalMessage>
                    </>
                  ) : (
                    <>
                      <Styled.BodySearchUnityModalMessageError>
                        Sua pesquisa por {newSearch} não correspondeu a nenhum
                        resultado.
                      </Styled.BodySearchUnityModalMessageError>

                      <Styled.BodySearchUnityModalMessage>
                        Se preferir, pesquise novamente utilizando as seguintes
                        sugestões:
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Certifique-se de que todas as palavras estejam
                        digitadas corretamente;
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Tente escolher palavras-chave diferentes das que você
                        digitou;
                      </Styled.BodySearchUnityModalMessage>
                      <Styled.BodySearchUnityModalMessage>
                        - Use termos mais genéricos, ou seja, com um conceito
                        mais amplo.
                      </Styled.BodySearchUnityModalMessage>
                    </>
                  )}
                </Styled.ContainerSearchMessageUnityModal>
              ) : (
                <TableMyOportunity
                  data={dataOportunity}
                  searchFilter={search}
                  fase={fase}
                  onClick={navigation}
                />
              )}
            </Flex>
          )}
        </>
      </Flex>
    </>
  );
};

export default MyOportunityComponent;
