import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import { IUpdateDataClientComponent } from "src/@types/Sales";
import * as Styled from "../Steps/stylesView&Create";
import Alert from "@components/common/Alert";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LoginContext } from "@context/loginContext";
import InputText from "@components/Sales/Inputs/InputText";
import InputDate from "@components/Sales/Inputs/InputDate";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import {
  optionCivilStatus,
  optionCommercialRegionalSP,
  optionMarriageRegime,
  optionNationality,
} from "@helpers/optionSelect";
import { documentMask, rgMask } from "@helpers/format";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { format, isValid, parse } from "date-fns";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { ptBR } from "date-fns/locale";
import { optionProfession } from "./options";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import salesServiceInstance from "@services/Sales/SalesService";
import { isEmail } from "@helpers/emailCheck";
import { theme } from "src/styles/theme";
import AdressClientComponent from "./SectionAdressUpdateDataClient/AdressClientComponent";

type Endereco = {
  idEnderecoSalesforce: string;
  numero: string;
  bairro: string;
  logradouro: string;
  uf: string;
  cidade: string;
  cep: string;
  tipoDeRegistro: string;
};

type RelacionamentoComprador = {
  idRelacionamentoComprador: string;
  codigoRelacionamentoComComprador: string;
  nome: string;
  cpf: string;
  enderecos: Endereco[];
  compradorPrincipal: boolean;
};

const UpdateDataClientComponent = ({
  onBack,
  onSuccess,
  isRequired = "",
  loadindSuccess,
  relationship,
  setRelationship,
  currentIndex,
  setCurrentIndex,
}: IUpdateDataClientComponent) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { signOut } = React.useContext(LoginContext);
  const { accessCreateOp, setAccessCreateOp, requireAdress } =
    React.useContext(OportunityContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingGetOportunity, setLoadingGetOportunity] = React.useState(false);
  const [name, setName] = React.useState("");
  const [accountIdSalesforce, setAccountIdSalesforce] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [rg, setRg] = React.useState("");
  const [dispatcherOrg, setDispatcherOrg] = React.useState("");
  const [dateIssue, setDateIssue] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [civilStatus, setCivilStatus] = React.useState("");
  const [require, setRequire] = React.useState(false);
  const [profession, setProfession] = React.useState("");
  const [selectProfession, setSelectProfession] = React.useState("");
  const [loadingProfession, setLoadingProfession] = React.useState(false);
  const [dataProfession, setDataProfession] = React.useState([]);
  const [idProfession, setIdProfession] = React.useState("");
  const [weddingDate, setWeddingDate] = React.useState("");
  const [marriageRegime, setMarriageRegime] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [commercialRegional, setCommercialRegional] = React.useState("");
  const [existCommercialRegional, setExistCommercialRegional] =
    React.useState(false);
  const [regional, setRegional] = React.useState("");

  const [isUpdateAdress, setIsUpdateAdress] = React.useState(false);
  const [cancelButtonAdress, setCancelButtonAdress] = React.useState(false);

  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleDateFormat = (date: string) => {
    if (!date) {
      return null;
    }
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'00:00:00'Z'");
    return formattedDate;
  };

  const handleDateFormatInput = (dateString: string) => {
    if (!dateString) return "";

    try {
      const inputFormat1 = "dd/MM/yyyy";
      const inputFormat2 = "yyyy-MM-dd'T'HH:mm:ssX";

      let date;

      date = parse(dateString.trim(), inputFormat1, new Date(), {
        locale: ptBR,
      });
      if (!isValid(date)) {
        // Se inválido, tenta parsear como formato ISO
        date = parse(dateString.trim(), inputFormat2, new Date());
      }

      if (!isValid(date)) {
        console.error("Data inválida fornecida: ", dateString);
        return "";
      }

      const outputFormat = "yyyy-MM-dd";
      return format(date, outputFormat);
    } catch (error) {
      console.error("Erro ao formatar a data: ", error);
      return "";
    }
  };

  const handleGetProffesion = async () => {
    setLoadingProfession(true);

    try {
      const response = await oportunityServiceInstance.getProfission();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingProfession(false);
        setDataProfession([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingProfession(false);
        setIsUnauthorized(true);
        setDataProfession([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataProfession(dataArray);
      setLoadingProfession(false);
    } catch (error) {
      setLoadingProfession(false);
      console.log("errorHandleGetProffesion", error);
    }
  };

  const renderedOptionsProfession = optionProfession(
    dataProfession,
    selectProfession,
    setSelectProfession,
    setProfession,
    setIdProfession
  );

  const handleUpdateOp = async () => {
    setLoading(true);
    try {
      const body = {
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        oportunidadeId: accessCreateOp.oportunidadeId,
        NomeConta: name === undefined || name === "" ? null : name,
        accountIdSalesforce: accountIdSalesforce,
        dataNascimento: handleDateFormat(dateBirth),
        nacionalidade: nationality,
        cpf: cpf,
        rg: rg,
        dataExpedicaoRG: handleDateFormat(dateIssue),
        orgaoExpedidorRG: dispatcherOrg,
        profissao: profession,
        estadoCivil: civilStatus,
        dataCasamento: handleDateFormat(weddingDate),
        regimeCasamento: marriageRegime,
        emailPrincipal: email,
        regionalComercial: commercialRegional,
      };
      const bodyNotRegionl = {
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        oportunidadeId: accessCreateOp.oportunidadeId,
        NomeConta: name === undefined || name === "" ? null : name,
        accountIdSalesforce: accountIdSalesforce,
        dataNascimento: handleDateFormat(dateBirth),
        nacionalidade: nationality,
        cpf: cpf,
        rg: rg,
        dataExpedicaoRG: handleDateFormat(dateIssue),
        orgaoExpedidorRG: dispatcherOrg,
        profissao: profession,
        estadoCivil: civilStatus,
        dataCasamento: handleDateFormat(weddingDate),
        regimeCasamento: marriageRegime,
        emailPrincipal: email,
      };

      const response = await oportunityServiceInstance.updateOportunity(
        regional === "SP" ? body : bodyNotRegionl
      );

      if (response.success === false) {
        setLoading(false);
        displayMessageError({ message: response.err });
        return;
      } else {
        setIsUpdateAdress(true);
      }

      // setLoading(false);

      // displayMessage("Dados editados com sucesso!");
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleUpdateOp", error);
    }
  };

  const handleUpdateAccount = async () => {
    setLoading(true);

    try {
      const body = {
        oportunidadeIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        AccountIdSalesforce: accountIdSalesforce,
        Nome: name,
        DataNascimento: handleDateFormat(dateBirth),
        Nacionalidade: nationality,
        CPFCNPJ: cpf,
        RG: rg,
        DataExpedicaoRG: handleDateFormat(dateIssue),
        OrgaoExpedidorRG: dispatcherOrg,
        Profissao: profession,
        EstadoCivil: civilStatus,
        DataCasamento: handleDateFormat(weddingDate),
        RegimeCasamento: marriageRegime,
        Email: email,
        regionalComercial: commercialRegional,
      };
      const bodyNotRegionl = {
        oportunidadeIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        AccountIdSalesforce: accountIdSalesforce,
        Nome: name,
        DataNascimento: handleDateFormat(dateBirth),
        Nacionalidade: nationality,
        CPFCNPJ: cpf,
        RG: rg,
        DataExpedicaoRG: handleDateFormat(dateIssue),
        OrgaoExpedidorRG: dispatcherOrg,
        Profissao: profession,
        EstadoCivil: civilStatus,
        DataCasamento: handleDateFormat(weddingDate),
        RegimeCasamento: marriageRegime,
        Email: email,
      };

      const response = await salesServiceInstance.updateAccount(
        regional === "SP" ? body : bodyNotRegionl
      );

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        const errorMessage =
          typeof response.data === "string"
            ? response.data
            : JSON.stringify(response.data);
        displayMessageError({ message: errorMessage });
        return;
      }

      setLoading(false);

      if (currentIndex === 0) {
        handleUpdateOp();
      } else {
        setIsUpdateAdress(true);
      }
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleUpdateAccount", error);
    }
  };

  const handleSave = () => {
    if (currentIndex < relationship.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      onSuccess();
    }
  };

  const currentBuyer = relationship[currentIndex];

  React.useEffect(() => {
    const isCnpjComplete = documentMask(cpf)?.length === 18;
    const validDocument =
      documentMask(cpf)?.length < 14 ||
      (documentMask(cpf)?.length > 14 && !isCnpjComplete);

    const marriedStatusNotFilled =
      civilStatus === "Casado(a)" &&
      (marriageRegime === null ||
        marriageRegime === "" ||
        weddingDate === null ||
        weddingDate === "");

    if (isRequired === "AC") {
      const isRequirement =
        validDocument ||
        !cpf ||
        !rg ||
        marriedStatusNotFilled ||
        (!isEmail(email) && !!email) ||
        (regional === "SP" && !commercialRegional);

      setRequire(isRequirement);
    } else {
      const isRequirement =
        validDocument ||
        !name ||
        !cpf ||
        !rg ||
        !dispatcherOrg ||
        !dateIssue ||
        !dateBirth ||
        !nationality ||
        !profession ||
        marriedStatusNotFilled ||
        (!isEmail(email) && !!email) ||
        (regional === "SP" && !commercialRegional);

      setRequire(isRequirement);
    }
  }, [
    civilStatus,
    marriageRegime,
    weddingDate,
    cpf,
    dateBirth,
    dateIssue,
    dispatcherOrg,
    isRequired,
    name,
    nationality,
    profession,
    rg,
    email,
    regional,
    commercialRegional,
  ]);

  React.useEffect(() => {
    const handleGetOportunityById = async () => {
      setLoadingGetOportunity(true);
      try {
        const response = await oportunityServiceInstance.getOportunityById({
          OportunidadeId: accessCreateOp.oportunidadeId,
        });

        if (response.status === 401) {
          setLoadingGetOportunity(false);
          setIsUnauthorized(true);
          return;
        }

        const relacionamento: RelacionamentoComprador[] =
          response?.relacionamentosComprador;

        const sortedData = relacionamento.sort((a, b) =>
          a.compradorPrincipal ? -1 : 1
        );
        setRelationship(sortedData);
        setAccessCreateOp((prevState: any) => {
          return {
            ...prevState,
            nomeDaConta: response?.nomeDaConta,
          };
        });
        setAccountIdSalesforce(currentBuyer?.accountIdSalesforce);
        setRegional(currentBuyer?.regional);
        setExistCommercialRegional(currentBuyer?.regionalComercial !== null);

        const handleSetRegionalComercial = () => {
          if (currentBuyer?.regionalComercial) {
            return currentBuyer?.regionalComercial;
          } else {
            if (currentBuyer?.regional === "GO") {
              return "DF";
            } else if (
              currentBuyer?.regional !== "SP" ||
              currentBuyer?.regional === "GO"
            ) {
              return currentBuyer?.regional;
            } else {
              return currentBuyer?.regionalComercial;
            }
          }
        };

        setCommercialRegional(handleSetRegionalComercial());
        setName(currentBuyer?.nome);
        setCpf(currentBuyer?.cpf);
        setRg(currentBuyer?.rg);
        setDispatcherOrg(currentBuyer?.orgaoExpedidorRG);
        setWeddingDate(handleDateFormatInput(currentBuyer?.dataCasamento));
        setMarriageRegime(currentBuyer?.regimeCasamento);
        setDateIssue(handleDateFormatInput(currentBuyer?.dataExpedicaoRG));
        setDateBirth(handleDateFormatInput(currentBuyer?.dataNascimento));
        setNationality(currentBuyer?.nacionalidade);
        setProfession(
          currentBuyer?.profissao === "" || currentBuyer?.profissao === null
            ? ""
            : currentBuyer?.profissao
        );
        setSelectProfession(
          currentBuyer?.profissao === "" || currentBuyer?.profissao === null
            ? ""
            : currentBuyer?.profissao
        );
        setCivilStatus(currentBuyer?.estadoCivil);
        setEmail(currentBuyer?.email);
        setLoadingGetOportunity(false);
      } catch (error) {
        setLoadingGetOportunity(false);
        console.log("ErrorHandleGetOportunityById", error);
      }
    };

    handleGetOportunityById();
  }, [
    accessCreateOp.oportunidadeId,
    currentBuyer?.accountIdSalesforce,
    currentBuyer?.cpf,
    currentBuyer?.dataCasamento,
    currentBuyer?.dataExpedicaoRG,
    currentBuyer?.dataNascimento,
    currentBuyer?.email,
    currentBuyer?.estadoCivil,
    currentBuyer?.nacionalidade,
    currentBuyer?.nome,
    currentBuyer?.orgaoExpedidorRG,
    currentBuyer?.profissao,
    currentBuyer?.regimeCasamento,
    currentBuyer?.regional,
    currentBuyer?.regionalComercial,
    currentBuyer?.rg,
    setAccessCreateOp,
    setRelationship,
  ]);
  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingGetOportunity ? (
        <Styled.LoadingSpinner>
          <Spinner />
        </Styled.LoadingSpinner>
      ) : (
        <>
          <Accordion allowMultiple>
            <AccordionItem mt={"10px"} borderRadius={8}>
              <AccordionButton
                bg={theme.disable100}
                borderRadius={8}
                h={"50px"}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"bold"}
                  fontSize={"18px"}
                >
                  Dados pessoais
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <Styled.ContainerInputsGrid>
                  <InputText
                    isRequirement={isRequired === "AC" ? false : true}
                    label="Nome:"
                    placeholder="Nome"
                    name="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <InputText
                    isRequirement
                    label="CPF/CNPJ:"
                    placeholder="CPF/CNPJ"
                    name="CPF/CNPJ"
                    value={cpf}
                    onChange={(e) => setCpf(documentMask(e.target.value))}
                    maxLength={18}
                  />
                  <InputText
                    isRequirement
                    label="RG:"
                    placeholder="RG"
                    name="RG"
                    value={rg}
                    onChange={(e) => setRg(rgMask(e.target.value))}
                    maxLength={18}
                  />
                  <InputText
                    isRequirement={isRequired === "AC" ? false : true}
                    label="Org. Expedidor:"
                    placeholder="Org. Expedidor"
                    name="dispatcherOrg"
                    value={dispatcherOrg}
                    onChange={(e) => setDispatcherOrg(e.target.value)}
                  />
                  <InputDate
                    type="date"
                    isRequirement={isRequired === "AC" ? false : true}
                    max={getDateToDay()}
                    name={"dateIssue"}
                    placeholder="__/__/___"
                    label="Data de Emissão:"
                    value={dateIssue}
                    onChange={(e) => setDateIssue(e.target.value)}
                  />
                  <InputDate
                    type="date"
                    isRequirement={isRequired === "AC" ? false : true}
                    max={getDateToDay()}
                    name={"dateBirth"}
                    placeholder="__/__/___"
                    label="Data de nascimento:"
                    value={dateBirth}
                    onChange={(e) => setDateBirth(e.target.value)}
                  />

                  <InputText
                    label="E-mail:"
                    placeholder="E-mail"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!isEmail(email) && !!email}
                    messageError={"E-mail inválido"}
                  />

                  <InputSelect
                    isRequirement={isRequired === "AC" ? false : true}
                    widht="28%"
                    title="Nacionalidade:"
                    label={nationality || "Selecione uma opção"}
                    isSelected={nationality}
                    options={optionNationality}
                    selectedOption={nationality}
                    onChange={(e) => setNationality(e.currentTarget.value)}
                  />

                  <InputSelectSearch
                    isRequirement={isRequired === "AC" ? false : true}
                    width="30%"
                    height="25%"
                    title="Profissão:"
                    placeholder="Pesquise por profissão"
                    value={selectProfession}
                    onChangeInput={(e) => setSelectProfession(e.target.value)}
                    isSelected={profession}
                    onChangeIcon={() => {
                      setSelectProfession("");
                      setProfession("");
                    }}
                    onClickSearchIcon={handleGetProffesion}
                    loading={loadingProfession}
                  >
                    {renderedOptionsProfession}
                  </InputSelectSearch>

                  <InputSelect
                    widht="28%"
                    title="Estado Civil:"
                    label={civilStatus || "Selecione uma opção"}
                    isSelected={civilStatus}
                    options={optionCivilStatus}
                    selectedOption={civilStatus}
                    onChange={(e) => setCivilStatus(e.currentTarget.value)}
                  />

                  {civilStatus === "Casado(a)" && (
                    <>
                      <InputDate
                        type="date"
                        isRequirement
                        max={getDateToDay()}
                        name={"weddingDate"}
                        placeholder="__/__/___"
                        label="Data casamento:"
                        value={weddingDate}
                        onChange={(e) => setWeddingDate(e.target.value)}
                      />

                      <InputSelect
                        isRequirement
                        widht="30%"
                        title="Regime casamento:"
                        label={marriageRegime || "Selecione uma opção"}
                        isSelected={marriageRegime}
                        options={optionMarriageRegime}
                        selectedOption={marriageRegime}
                        onChange={(e) =>
                          setMarriageRegime(e.currentTarget.value)
                        }
                      />
                    </>
                  )}

                  {regional !== "SP" || commercialRegional === "DF" ? (
                    <InputText
                      isRequirement
                      readOnly
                      label="Regional Comercial:"
                      name="commercialRegional"
                      value={commercialRegional}
                    />
                  ) : (
                    <InputSelect
                      readOnly={existCommercialRegional}
                      isRequirement
                      title="Regional Comercial:"
                      label={commercialRegional || "Selecione uma opção"}
                      isSelected={commercialRegional}
                      options={optionCommercialRegionalSP}
                      selectedOption={commercialRegional}
                      onChange={(e) =>
                        setCommercialRegional(e.currentTarget.value)
                      }
                    />
                  )}
                </Styled.ContainerInputsGrid>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem mt={"10px"} borderRadius={8}>
              <AccordionButton
                bg={theme.disable100}
                borderRadius={8}
                h={"50px"}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"bold"}
                  fontSize={"18px"}
                >
                  Endereço
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <Styled.ContainerActivitySubTab>
                  <Flex flexDir={"column"}>
                    <AdressClientComponent
                      edit={true}
                      accountIdSalesforce={accountIdSalesforce}
                      relationship={currentBuyer}
                      setLoadingUpdateAdress={setLoading}
                      isCancel={cancelButtonAdress}
                      isClicked={isUpdateAdress}
                      setIsClicked={setIsUpdateAdress}
                      onSuccess={handleSave}
                    />
                  </Flex>
                </Styled.ContainerActivitySubTab>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooter>
              <Button
                error
                title={currentIndex === 0 ? "Cancelar" : "Voltar"}
                onClick={() => {
                  if (currentIndex === 0) {
                    onBack();
                  } else {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
              />

              <Button
                title={
                  loading || loadindSuccess ? <Spinner /> : "Salvar e Continuar"
                }
                onClick={handleUpdateAccount}
                disabled={loading || require || requireAdress || loadindSuccess}
              />
            </Styled.ContainerButtonFooter>
          </Styled.ContainerFooterBox>
        </>
      )}
    </>
  );
};

export default UpdateDataClientComponent;
