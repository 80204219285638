import * as Styled from "./styles";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { theme } from "src/styles/theme";

interface IAlert {
  onOpen: boolean;
  children: ReactNode;
  onClose?: any;
}

const NotifyAC = ({ onOpen, children, onClose }: IAlert) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={onOpen}
      onClose={onClose}
      size={"md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0} ml={2} mt={3}>
          <Styled.ContainerHeader>
            <Text fontSize={"18px"} fontWeight={"bold"} color={theme.text}>
              Inserir dados da AC
            </Text>
          </Styled.ContainerHeader>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={2}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotifyAC;
