import React from "react";
import { Button as ChakraButton } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

interface IButton {
  title: string | any;
  onClick?: React.FormEventHandler<HTMLButtonElement>;
  colorDisabled?: boolean;
  colorError?: boolean;
  disabled?: boolean;
  error?: boolean;
  colorInverter?: boolean;
}

const Button = ({
  onClick,
  title,
  disabled,
  colorDisabled,
  colorError,
  error,
  colorInverter,
}: IButton) => {
  function borderTheme() {
    if (colorDisabled || disabled) return "none";
    if (error || colorError) return `2px solid ${theme.error}`;
    if (colorInverter) return `2px solid ${theme.primary}`;
    return "none";
  }
  function backgroundTheme() {
    if (colorDisabled || disabled) return `${theme.disable}`;
    if (colorError) return `${theme.error}`;
    if (error) return "white";
    if (colorInverter) return "white";
    return `${theme.primary}`;
  }
  function textTheme() {
    if (colorDisabled || disabled) return "white";
    if (error) return `${theme.error}`;
    if (colorInverter) return `${theme.primary}`;
    return "white";
  }

  return (
    <ChakraButton
      onClick={onClick}
      disabled={disabled}
      isDisabled={disabled}
      _hover={{ opacity: 1 }}
      border={borderTheme()}
      h={"42px"}
      borderRadius={"8px"}
      fontSize={"14px"}
      alignItems={"center"}
      justifyContent={"center"}
      backgroundColor={backgroundTheme()}
      _disabled={{ bg: theme.disable }}
      color={textTheme()}
      opacity={0.8}
    >
      {title}
    </ChakraButton>
  );
};

export default Button;
