interface IIcon {
  width: string;
  height: string;
}

export const IconEdit = ({ width, height }: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15H2.098L12.7962 4.302L11.698 3.20375L1 13.902V15ZM0 16V13.4808L13.1807 0.286501C13.2832 0.195167 13.3963 0.124583 13.52 0.0747498C13.6438 0.0249164 13.7729 0 13.9072 0C14.0416 0 14.1717 0.0211661 14.2977 0.0634995C14.4236 0.105833 14.5398 0.182083 14.6462 0.29225L15.7135 1.3655C15.8237 1.47183 15.899 1.5885 15.9395 1.7155C15.9798 1.84267 16 1.96975 16 2.09675C16 2.23225 15.9772 2.36192 15.9315 2.48575C15.8858 2.60942 15.8132 2.7225 15.7135 2.825L2.51925 16H0ZM12.2375 3.7625L11.698 3.20375L12.7962 4.302L12.2375 3.7625Z"
      fill="#263958"
    />
  </svg>
);
